import { useMutation, useQuery } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import { StubStatus } from 'components/Stub/Stub'
import AddSkillMap from 'graphql/mutations/AddSkillMap'
import DeleteSkillMap from 'graphql/mutations/DeleteSkillMap'
import EditDefaultSkillMap from 'graphql/mutations/EditDefaultSkillMap'
import EditSkillMap from 'graphql/mutations/EditSkillMap'
import Tick from 'graphql/mutations/Tick'
import Untick from 'graphql/mutations/Untick'
import GetDefaultSkillMap from 'graphql/queries/GetDefaultSkillMap'
import GetSkillMaps from 'graphql/queries/GetSkillMaps'
import GetTicks from 'graphql/queries/GetTicks'
import GetTree from 'graphql/queries/GetTree'
import {
    DefaultSkillMap,
    Document,
    MutationAddSkillMapArgs,
    MutationDeleteSkillMapArgs,
    MutationEditDefaultSkillMapArgs,
    MutationEditSkillMapArgs,
    SkillMap,
    Tick as TickType,
} from 'graphql/types'
import { useShorten } from 'utils/types'

export interface MapPageApolloReturn {
    // Query results
    tree: Document[]
    skillMaps: SkillMap[]
    defaultSkillMap: DefaultSkillMap
    ticks: TickType[]
    // Mutations
    addSkillMap: (input: MutationAddSkillMapArgs) => void
    editSkillMap: (input: MutationEditSkillMapArgs) => void
    deleteSkillMap: (input: MutationDeleteSkillMapArgs) => void
    editDefaultSkillMap: (input: MutationEditDefaultSkillMapArgs) => void
    tick: (itemId: string) => void
    untick: (itemId: string) => void

    // Error handling
    state?: StubStatus
}
export const useMapPageApollo = (): MapPageApolloReturn => {
    const { keycloak } = useKeycloak()
    const userId = keycloak.tokenParsed?.sub

    // Queries
    const { loading: loadingTree, error: errorTree, data: dataTree } = useQuery(GetTree)
    const { loading: loadingMaps, error: errorMaps, data: dataMaps } = useQuery(GetSkillMaps)
    const {
        loading: loadingDefaultSkillMap,
        error: errorDefaultSkillMap,
        data: dataDefaultSkillMap,
    } = useQuery(GetDefaultSkillMap)

    const {
        loading: loadingTicks,
        error: errorTicks,
        data: dataTicks,
    } = useQuery(GetTicks, {
        variables: {
            userId: keycloak.tokenParsed?.sub,
        },
    })

    // Mutations
    const [addSkillMap] = useMutation(AddSkillMap, {
        update(cache, { data }) {
            const currentCache: { getSkillMaps: SkillMap[] } | null = cache.readQuery({
                query: GetSkillMaps,
            })
            if (currentCache === null) return

            cache.writeQuery({
                query: GetSkillMaps,
                data: {
                    getSkillMaps: currentCache.getSkillMaps.concat(data.addSkillMap),
                },
            })
        },
    })
    const [editSkillMap] = useMutation(EditSkillMap)
    const [deleteSkillMap] = useMutation(DeleteSkillMap, {
        update(cache, { data }) {
            const currentCache: { getSkillMaps: SkillMap[] } | null = cache.readQuery({
                query: GetSkillMaps,
            })
            if (currentCache === null) return

            let newSkillMaps: SkillMap[] = currentCache.getSkillMaps
            const skillMap: SkillMap = data.deleteSkillMap
            newSkillMaps = newSkillMaps.filter((d) => d.id !== skillMap.id)

            cache.writeQuery({
                query: GetSkillMaps,
                data: {
                    getSkillMaps: newSkillMaps,
                },
            })
        },
    })
    const [editDefaultSkillMap] = useMutation(EditDefaultSkillMap)

    const [tick] = useMutation(Tick, {
        update(cache, { data }) {
            const currentCache: { getTicks: TickType[] } | null = cache.readQuery({
                query: GetTicks,
                variables: {
                    userId,
                },
            })
            if (currentCache === null) return
            cache.writeQuery({
                query: GetTicks,
                data: {
                    getTicks: currentCache.getTicks.concat(data.tick),
                },
                variables: {
                    userId,
                },
            })
        },
    })
    const [untick] = useMutation(Untick, {
        update(cache, { data }) {
            // https://github.com/apollographql/apollo-client/issues/6451
            cache.modify({
                fields: {
                    getTicks(list, { readField }) {
                        return list.filter((n: any) => readField('id', n) !== data.untick.id)
                    },
                },
            })
        },
    })

    const shortedAddSkillMap = useShorten<MutationAddSkillMapArgs>(addSkillMap)
    const shortedEditSkillMap = useShorten<MutationEditSkillMapArgs>(editSkillMap)
    const shortedDeleteSkillMap = useShorten<MutationDeleteSkillMapArgs>(deleteSkillMap)
    const shortedEditDefaultSkillmap = useShorten<MutationEditDefaultSkillMapArgs>(editDefaultSkillMap)

    if (
        loadingTree ||
        loadingMaps ||
        loadingTicks ||
        loadingDefaultSkillMap ||
        errorTree ||
        errorMaps ||
        errorTicks ||
        errorDefaultSkillMap ||
        (dataTree && !dataTree.getTree) ||
        (dataMaps && !dataMaps.getSkillMaps) ||
        (dataTicks && !dataTicks.getTicks) ||
        (dataDefaultSkillMap && !dataDefaultSkillMap.getDefaultSkillMap)
    )
        return {
            defaultSkillMap: { id: '', defaultSkillMap: '' },
            skillMaps: [],
            ticks: [],
            tree: [],

            addSkillMap: () => null,
            deleteSkillMap: () => null,
            editDefaultSkillMap: () => null,
            editSkillMap: () => null,
            tick: () => null,
            untick: () => null,
            state:
                loadingTree || loadingMaps || loadingTicks
                    ? StubStatus.loading
                    : errorTree || errorMaps || errorTicks
                    ? StubStatus.error
                    : StubStatus.noData,
        }

    const defaultSkillMap: DefaultSkillMap = dataDefaultSkillMap.getDefaultSkillMap[0]
    const tree: Document[] = dataTree.getTree
    const skillMaps: SkillMap[] = dataMaps.getSkillMaps
    const ticks: TickType[] = dataTicks.getTicks

    return {
        defaultSkillMap,
        skillMaps,
        ticks,
        tree,
        addSkillMap: shortedAddSkillMap,
        editSkillMap: shortedEditSkillMap,
        deleteSkillMap: shortedDeleteSkillMap,
        editDefaultSkillMap: shortedEditDefaultSkillmap,
        tick: (itemId) => tick({ variables: { itemId, userId } }),
        untick: (itemId) => untick({ variables: { itemId, userId } }),
    }
}
