import { MutationFunctionOptions, OperationVariables } from '@apollo/client'
import {
    DefaultSkillMap,
    DefaultSkillMapInput,
    Document,
    DocumentInput,
    Maybe,
    Scalars,
    SkillMap,
    SkillMapInput,
} from 'graphql/types'
import { useCallback } from 'react'
import { v4 } from 'uuid'

export type Loadstate = {
    __typename?: 'Loadstate'
    loading: Scalars['Boolean']
    errors: Array<Scalars['String']>
}

export type ActiveUser = {
    __typename?: 'ActiveUser'
    id: Scalars['ID']
    email: Scalars['String']
    ticks: Array<Scalars['ID']>
    roles: Array<Scalars['String']>
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    title: Scalars['String']
    content: Scalars['String']
    messages?: Maybe<Array<Scalars['String']>>
}

// This is stupid, but apollo is stupid too, so it cancels out
// It fixes the __typename issue...
export const cleanDocument = (document: Document, newIds?: boolean): DocumentInput => {
    return {
        id: document.id,
        title: document.title,
        description: document.description,
        order: document.order,
        items: document.items.map((item) => {
            return {
                id: newIds ? v4() : item.id,
                createdAt: item.createdAt,
                lastEdit: item.lastEdit,
                requirements: item.requirements,
                title: item.title,
            }
        }),
        links: document.links.map((link) => ({
            id: newIds ? v4() : link.id,
            label: link.label,
            url: link.url,
            comment: link.comment,
        })),
        parentId: document.parentId,
        ready: document.ready,
    }
}

export const cleanSkillMap = (skillMap: SkillMap, newIds?: boolean): SkillMapInput => {
    return {
        id: skillMap.id,
        backgroundColor: skillMap.backgroundColor,
        description: skillMap.description,
        title: skillMap.title,
        skillNodes: skillMap.skillNodes
            ? skillMap.skillNodes.map((node) => {
                  return {
                      id: newIds ? v4() : node.id,
                      connections: node.connections,
                      content: node.content,
                      nodeType: node.nodeType,
                      x: node.x,
                      y: node.y,
                      options: node.options,
                  }
              })
            : [],
    }
}

export const cleanDefaultSkillMap = (defaultSkillMap: DefaultSkillMap): DefaultSkillMapInput => {
    return {
        id: defaultSkillMap.id ?? v4(),
        defaultSkillMap: defaultSkillMap.defaultSkillMap,
    }
}

export const createEmptySkillMap = (): SkillMap => {
    return { id: v4(), backgroundColor: '#fff', description: '', skillNodes: [], title: '' }
}

export const createEmptyDocument = (): Document => {
    return {
        description: '',
        id: v4(),
        items: [],
        links: [],
        order: 0,
        parentId: '',
        ready: false,
        title: '',
    }
}

export interface SuperKeycloakInstance extends Keycloak.KeycloakTokenParsed {
    name: string
}

// Short way to create a shorter return function
// Takes a mutation and a type, returns a function that takes that type and puts it into the mutation
// Obviously only works when direct passes are used, not when we need extra paramaters like in e.g. tick/untick
export const useShorten = <MutationInputType>(
    mutation: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) => void,
): ((input: MutationInputType) => void) => {
    return useCallback((input: MutationInputType) => mutation({ variables: input }), [mutation])
}
