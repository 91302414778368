import { useMutation, useQuery } from '@apollo/client'
import { ChevronRight, CloseFullscreen, ExpandMore, OpenInFull } from '@mui/icons-material'
import { TreeView } from '@mui/lab'
import { Box, Button, Divider, IconButton, Switch, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { useKeycloak } from '@react-keycloak/web'
import { DocumentEdit } from 'components/DocumentEdit/DocumentEdit'
import { DocumentView } from 'components/DocumentView/DocumentView'
import { SkillTreeItem } from 'components/SkillTreeItem/SkillTreeItem'
import { StyledTooltip } from 'components/StyledTooltip/StyledTooltip'
import { TitleBar } from 'components/TitleBar/TitleBar'
import AddDocument from 'graphql/mutations/AddDocument'
import DeleteDocument from 'graphql/mutations/DeleteDocument'
import EditDocument from 'graphql/mutations/EditDocument'
import GetTicks from 'graphql/queries/GetTicks'
import GetTree from 'graphql/queries/GetTree'
import { Item, Link, SkillLevel } from 'graphql/types'
import { cloneDeep } from 'lodash'
import { countTotalItems, countUncheckedItems } from 'pages/OverviewPage/countFunction'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import colors from 'utils/colors'
import { calculateDepth, Document, treeFindByAttribute, treeFindById, treeFromFlatTree, treeSort } from 'utils/tree'
import { cleanDocument, SuperKeycloakInstance } from 'utils/types'
import { v4 } from 'uuid'

export const OverviewPage = (): JSX.Element => {
    const { keycloak } = useKeycloak()
    const [skillLevel, setSkillLevel] = React.useState<SkillLevel>(SkillLevel.JUNIOR)
    const { id }: { id: string } = useParams()
    const [selected, setSelected] = React.useState<string>(id ?? '')
    const [expanded, setExpanded] = React.useState<string[]>([])
    const [editing, setEditing] = React.useState<boolean>(false)
    const [cloningDocument, setCloningDocument] = React.useState<Document | undefined>(undefined)
    const [editingDocument, setEditingDocument] = React.useState<Document | undefined>(undefined)
    const [movingDocument, setMovingDocument] = React.useState<Document | undefined>(undefined)
    const [selectedDocument, setSelectedDocument] = React.useState<Document | undefined>(undefined)
    const [selectedPrevious, setSelectedPrevious] = React.useState<string>('')
    const [selectedNext, setSelectedNext] = React.useState<string>('')
    const [parentDoc, setParentDoc] = React.useState<Document | undefined>(undefined)
    const [tree, setTree] = React.useState<Document[]>([])
    const userName = (keycloak.tokenParsed as SuperKeycloakInstance)?.name
    const [open, setOpen] = React.useState(false)
    const [readyFilter, setReadyFilter] = React.useState(false)
    const [itemFocus, setItemFocus] = React.useState<Item | undefined>(undefined)
    const [linkFocus, setLinkFocus] = React.useState<Link | undefined>(undefined)

    const { loading, error, data } = useQuery(GetTree)
    const {
        loading: loading2,
        error: error2,
        data: data2,
    } = useQuery(GetTicks, {
        variables: {
            userId: keycloak.tokenParsed?.sub,
        },
    })
    const [addDocument] = useMutation(AddDocument, {
        update(cache, { data }) {
            const currentCache: { getTree: Document[] } | null = cache.readQuery({
                query: GetTree,
            })
            if (currentCache === null) return

            cache.writeQuery({
                query: GetTree,
                data: {
                    getTree: currentCache.getTree.concat(data.addDocument),
                },
            })
        },
    })

    const [saveDocument, { loading: loadingSave }] = useMutation(EditDocument, {
        onCompleted() {
            setEditing(false)
        },
    })
    const [confirmDocument, { loading: loadingConfirm }] = useMutation(EditDocument)

    const [deleteDocument] = useMutation(DeleteDocument, {
        update(cache, { data }) {
            const currentCache: { getTree: Document[] } | null = cache.readQuery({
                query: GetTree,
            })
            if (currentCache === null) return

            let newTree: Document[] = currentCache.getTree
            const document: Document = data.deleteDocument
            newTree = newTree.filter((d) => d.id !== document.id)

            newTree = newTree.map((d) => {
                if (d.parentId === document.parentId && d.order > document.order)
                    return {
                        ...d,
                        order: d.order - 1,
                    }
                else return d
            })

            let count = currentCache.getTree.filter((d) => d.parentId === document.parentId).length - 1
            newTree = newTree.map((d) => {
                if (d.parentId === document.id)
                    return {
                        ...d,
                        parentId: document.parentId,
                        order: count++,
                    }
                return d
            })

            cache.writeQuery({
                query: GetTree,
                data: {
                    getTree: newTree,
                },
            })
        },
    })
    const setPreviousAndNext = () => {
        if (data && data.getTree) {
            const tree = treeSort(treeFromFlatTree(data.getTree), (a, b) => (a.order > b.order ? 1 : -1))
            const found = treeFindById(tree, selected)
            if (found) {
                const prev = treeFindByAttribute(
                    tree,
                    (document) => document.parentId === found.parentId && document.order === found.order - 1,
                )
                const next = treeFindByAttribute(
                    tree,
                    (document) => document.parentId === found.parentId && document.order === found.order + 1,
                )

                if (prev) {
                    setSelectedPrevious(prev.id)
                } else {
                    setSelectedPrevious('')
                }
                if (next) {
                    setSelectedNext(next.id)
                } else {
                    setSelectedNext('')
                }
            }
        }
    }
    React.useEffect(() => {
        setPreviousAndNext()
    }, [selected, data])

    React.useEffect(() => {
        setEditingDocument(selectedDocument)
        history.replaceState(null, '', '/#/Tree/' + selected)
    }, [selectedDocument])

    React.useEffect(() => {
        if (data && data.getTree && data.getTree.length > 0 && selected === '') {
            setSelected(treeFromFlatTree(data.getTree)[0].id)
        }
    }, [data])

    React.useEffect(() => {
        if (data === undefined) return
        if (expanded.length > 0) return

        const tree: Document[] = data.getTree
        let document = tree.find((d) => d.id === id)
        if (document === undefined) return
        const toExpand = []
        while (true) {
            document = tree.find((d) => d.id === document?.parentId)
            if (document === undefined) return

            toExpand.push(document.id)
            if (document.parentId === '') break
        }
        setExpanded(toExpand)
    }, [data, id])

    const handleKeyUp = (event: {
        key: string
        ctrlKey: boolean
        code: string
        shiftKey: boolean
        altKey: boolean
    }) => {
        if (tree)
            switch (event.key) {
                case 'l':
                    // add a link if edit mode is active
                    if (!event.ctrlKey && event.altKey && editingDocument && editing) {
                        const copy = cloneDeep(editingDocument)
                        copy.links.push({ id: v4(), url: '', label: '' })
                        setEditingDocument(copy)
                        setTimeout(() => {
                            const element = document.getElementById(`l${copy.links.length - 1}`)
                            if (element) {
                                element.focus()
                            }
                        }, 100)
                    }
                    // delete selected link if edit mode is active
                    if (event.ctrlKey && event.altKey && editingDocument && editing) {
                        const copy = cloneDeep(editingDocument)
                        if (linkFocus) {
                            const index = editingDocument.links.findIndex((l) => l.id === linkFocus.id)
                            if (index !== -1) {
                                copy.links.splice(index, 1)
                                setEditingDocument(copy)
                                if (index > 0) document.getElementById(`l${index - 1}`)?.focus()
                                else document.getElementById(`l0`)?.focus()
                            }
                        }
                    }
                    break
                case 'i':
                    // add a item if edit mode is active
                    if (!event.ctrlKey && event.altKey && editingDocument && editing) {
                        const copy = cloneDeep(editingDocument)
                        copy.items.push({
                            id: v4(),
                            title: '',
                            createdAt: new Date().toISOString(),
                            lastEdit: new Date().toISOString(),
                            requirements: 9,
                        })
                        setEditingDocument(copy)
                        setTimeout(() => {
                            const element = document.getElementById(`c${copy.items.length - 1}`)
                            if (element) {
                                element.focus()
                            }
                        }, 100)
                    }
                    // delete selected item if edit mode is active
                    if (event.ctrlKey && event.altKey && editingDocument && editing) {
                        const copy = cloneDeep(editingDocument)
                        if (itemFocus) {
                            const index = editingDocument.items.findIndex((i) => i.id === itemFocus.id)
                            if (index !== -1) {
                                copy.items.splice(index, 1)
                                setEditingDocument(copy)
                                if (index > 0) document.getElementById(`c${index - 1}`)?.focus()
                                else document.getElementById(`c0`)?.focus()
                            }
                        }
                    }
                    break
                case 'e':
                    // start edit mode
                    setEditing(true)
                    setCloningDocument(undefined)
                    setMovingDocument(undefined)
                    break
                case 'c':
                    // start clone mode
                    if (!editing) {
                        setCloningDocument(selectedDocument)
                        setMovingDocument(undefined)
                    }
                    break
                case 'm':
                    // start move mode
                    if (!editing) {
                        setMovingDocument(selectedDocument)
                        setCloningDocument(undefined)
                    }
                    break
                case 'x':
                    if (event.altKey) {
                        // delete selected document
                        setOpen(true)
                    }
                    break
                case 'r':
                    if (event.altKey) {
                        // add new root
                        const id = v4()
                        addDocument({
                            variables: {
                                document: cleanDocument({
                                    id,
                                    description: '',
                                    items: [],
                                    links: [],
                                    title: 'New document',
                                    parentId: '',
                                    order: tree.length,
                                    ready: false,
                                }),
                            },
                        })
                        setSelected(id)
                        setExpanded([...expanded, id])
                    }
                    break
                case 't':
                    if (event.altKey && selectedDocument) {
                        // add document to selected document
                        const id = v4()
                        addDocument({
                            variables: {
                                document: cleanDocument({
                                    id: id,
                                    description: '',
                                    items: [],
                                    links: [],
                                    title: 'New document',
                                    parentId: selected,
                                    order: selectedDocument.documents.length,
                                    ready: false,
                                }),
                            },
                        })
                        setSelected(id)
                        setExpanded([...expanded, selected])
                    }
                    break
                case 'Escape':
                    // cancel edit and clone modes
                    setEditing(false)
                    setCloningDocument(undefined)
                    setEditingDocument(selectedDocument)
                    setMovingDocument(undefined)
                    break
                case 'Enter':
                    if (event.ctrlKey && editingDocument) {
                        // save document
                        confirmDocument({
                            variables: {
                                document: cleanDocument(editingDocument),
                            },
                        })
                    }
                    break
                case 'PageUp':
                    // move document up in the tree
                    if (selectedDocument && selectedDocument.order > 0) {
                        confirmDocument({
                            variables: {
                                document: cleanDocument({
                                    ...selectedDocument,
                                    order: selectedDocument.order - 1,
                                }),
                            },
                        })
                        const prev = treeFindById(tree, selectedPrevious)
                        if (prev) {
                            confirmDocument({
                                variables: {
                                    document: cleanDocument({
                                        ...prev,
                                        order: prev.order + 1,
                                    }),
                                },
                            })
                        }
                    }
                    setTimeout(() => {
                        const element = document.getElementById(`treeBox-${selected}`)
                        if (element) {
                            element.focus()
                        }
                    }, 100)
                    break
                case 'PageDown':
                    // move document down in the tree
                    if (selectedDocument) {
                        const parent =
                            selectedDocument.parentId === ''
                                ? tree
                                : treeFindById(tree, selectedDocument.parentId)?.documents
                        if (parent && selectedDocument.order < parent.length - 1)
                            confirmDocument({
                                variables: {
                                    document: cleanDocument({
                                        ...selectedDocument,
                                        order: selectedDocument.order + 1,
                                    }),
                                },
                            })

                        const next = treeFindById(tree, selectedNext)
                        if (next) {
                            confirmDocument({
                                variables: {
                                    document: cleanDocument({
                                        ...next,
                                        order: next.order - 1,
                                    }),
                                },
                            })
                        }
                    }
                    setTimeout(() => {
                        const element2 = document.getElementById(`treeBox-${selected}`)
                        if (element2) {
                            element2.focus()
                        }
                    }, 100)
                    break
                default:
                    break
            }
    }

    React.useEffect(() => {
        window.addEventListener('keyup', handleKeyUp)
        // cleanup this component
        return () => {
            window.removeEventListener('keyup', handleKeyUp)
        }
    })

    React.useEffect(() => {
        if (data && data.getTree) {
            setTree(treeSort(treeFromFlatTree(data.getTree), (a, b) => (a.order > b.order ? 1 : -1)))
            setSelectedDocument(treeFindById(treeFromFlatTree(data.getTree), selected))
            const temp = treeFindById(treeFromFlatTree(data.getTree), selected)
            setParentDoc(treeFindById(treeFromFlatTree(data.getTree), temp ? temp.parentId : ''))
        }
    }, [data, selected])

    if (loading) return <div>Loading</div>
    if (error) return <div>error</div>
    if (!data.getTree) return <div>No data</div>
    if (loading2) return <div>Loading</div>
    if (error2) return <div>error</div>
    if (!data2.getTicks) return <div>No data</div>
    const countedUnchecked = countUncheckedItems(tree, skillLevel, data2.getTicks, readyFilter)
    const countedTotal = countTotalItems(tree, skillLevel, readyFilter)

    const renderDocument = (branch: Document, depth: number, index: number): JSX.Element => {
        const count = countUncheckedItems([branch], skillLevel, data2.getTicks, readyFilter)
        if (depth < 7)
            return (
                <SkillTreeItem
                    key={branch.id + index}
                    id={branch.id}
                    title={branch.title}
                    recommended={count.recommended}
                    required={count.required}
                    depth={depth}
                >
                    {branch.documents
                        .filter((d) => !readyFilter || d.ready)
                        .map((document, index) => renderDocument(document, depth + 1, index))}
                </SkillTreeItem>
            )
        return (
            <Typography variant="subtitle1" color="primary">
                Beyond This Point There Be Dragons
            </Typography>
        )
    }

    const findSelected = (): string => {
        const docu = cloneDeep(selectedDocument)
        if (docu) {
            if (docu.parentId === '') {
                if (docu.order > 0) {
                    return tree.find((doc) => doc.parentId === docu.parentId && doc.order === docu.order - 1)?.id ?? ''
                }
                return ''
            } else {
                return docu.parentId
            }
        }
        return ''
    }

    return (
        <Box style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/qubidu_background.png)` }} height={'100vh'}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={1280}
                boxShadow={6}
                marginX={'auto'}
                bgcolor={colors.white}
                maxHeight={'100%'}
                height={'100%'}
                flex={1}
            >
                <TitleBar
                    selectedDocument={selectedDocument}
                    editingDocument={editingDocument}
                    editing={editing}
                    userName={userName}
                    skillLevel={skillLevel}
                    setSkillLevel={setSkillLevel}
                    cloningDocument={cloningDocument}
                    movingDocument={movingDocument}
                    title={'Skill Tree'}
                />
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    minHeight={'calc(100vh - 72px)'}
                    maxHeight={'calc(100vh - 72px)'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        flexBasis={0}
                        flexGrow={1}
                        borderRight={2}
                        borderColor={colors.lightGray}
                        boxSizing={'border-box'}
                        maxHeight={'100%'}
                    >
                        <Box display={'flex'} flexDirection={'column'} bgcolor={colors.white} paddingX={1}>
                            <LinearProgress
                                style={{
                                    flexGrow: 1,
                                    height: 20,
                                    marginTop: 8,
                                    borderRadius: 3,
                                }}
                                variant={'determinate'}
                                value={
                                    countedTotal.required > 0
                                        ? ((countedTotal.required - countedUnchecked.required) /
                                              countedTotal.required) *
                                          100
                                        : 0
                                }
                            />
                            <Typography
                                variant={'caption'}
                                color={colors.white}
                                zIndex={1}
                                marginTop={-2.625}
                                paddingLeft={1}
                            >
                                Required: {countedTotal.required - countedUnchecked.required}/{countedTotal.required}
                            </Typography>
                            <LinearProgress
                                style={{
                                    flexGrow: 1,
                                    height: 20,
                                    marginTop: 8,
                                    borderRadius: 3,
                                }}
                                variant={'determinate'}
                                value={
                                    countedTotal.recommended > 0
                                        ? ((countedTotal.recommended - countedUnchecked.recommended) /
                                              countedTotal.recommended) *
                                          100
                                        : 0
                                }
                            />
                            <Typography
                                variant={'caption'}
                                color={colors.white}
                                zIndex={1}
                                marginTop={-2.625}
                                paddingLeft={1}
                            >
                                Recommended: {countedTotal.recommended - countedUnchecked.recommended}/
                                {countedTotal.recommended}
                            </Typography>
                            <LinearProgress
                                style={{
                                    flexGrow: 1,
                                    height: 20,
                                    marginTop: 8,
                                    borderRadius: 3,
                                }}
                                variant={'determinate'}
                                value={
                                    countedTotal.optional > 0
                                        ? ((countedTotal.optional - countedUnchecked.optional) /
                                              countedTotal.optional) *
                                          100
                                        : 0
                                }
                            />
                            <Typography
                                variant={'caption'}
                                color={colors.white}
                                zIndex={1}
                                marginTop={-2.625}
                                paddingLeft={1}
                            >
                                Optional: {countedTotal.optional - countedUnchecked.optional}/{countedTotal.optional}
                            </Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            paddingX={1}
                            style={{ overflowY: 'auto', overflowX: 'hidden' }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                position={'sticky'}
                                top={0}
                                zIndex={1}
                                bgcolor={colors.white}
                                width={'100%'}
                                paddingRight={8}
                            >
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Typography marginLeft={3.8}>Module</Typography>
                                    <StyledTooltip title="Expand All">
                                        <span>
                                            <IconButton
                                                onClick={() => setExpanded(data.getTree.map((doc: Document) => doc.id))}
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    marginLeft: 'auto',
                                                    '&:hover': {
                                                        color: colors.main,
                                                    },
                                                }}
                                            >
                                                <OpenInFull
                                                    sx={{ width: 16, height: 16, transform: 'rotate(-45deg)' }}
                                                />
                                            </IconButton>
                                        </span>
                                    </StyledTooltip>
                                    <StyledTooltip title="Collapse All">
                                        <span>
                                            <IconButton
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    '&:hover': {
                                                        color: colors.main,
                                                    },
                                                }}
                                                onClick={() => setExpanded([])}
                                            >
                                                <CloseFullscreen
                                                    sx={{ width: 16, height: 16, transform: 'rotate(-45deg)' }}
                                                />
                                            </IconButton>
                                        </span>
                                    </StyledTooltip>
                                    <StyledTooltip title="Filter by ready state">
                                        <span>
                                            <Switch
                                                size="small"
                                                onChange={(event) => {
                                                    setReadyFilter(event.target.checked)
                                                    if (!selectedDocument?.ready)
                                                        setSelected(tree.find((doc) => doc.ready === true)?.id ?? '')
                                                    if (!selected) setSelected(tree.length > 0 ? tree[0].id : '')
                                                }}
                                            />
                                        </span>
                                    </StyledTooltip>
                                    <Typography
                                        variant={'caption'}
                                        marginLeft={'auto'}
                                        style={{ minWidth: 32, width: 3 }}
                                    >
                                        REQ
                                    </Typography>
                                    <Typography variant={'caption'} style={{ minWidth: 32, width: 32 }}>
                                        REC
                                    </Typography>
                                </Box>
                                <Divider style={{ marginTop: 8 }} />
                            </Box>
                            <TreeView
                                id={'treeBox'}
                                selected={selected}
                                onNodeSelect={(_event: any, nodeIds: string) => {
                                    setSelected(nodeIds)
                                }}
                                expanded={expanded}
                                onNodeToggle={(_event, nodeIds) => setExpanded(nodeIds)}
                                defaultExpandIcon={<ChevronRight style={{ width: 24, height: 24 }} />}
                                defaultCollapseIcon={<ExpandMore style={{ width: 24, height: 24 }} />}
                            >
                                {tree
                                    .filter((d) => !readyFilter || d.ready)
                                    .map((document, index) => renderDocument(document, 0, index))}
                            </TreeView>
                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            marginTop={'auto'}
                            padding={1}
                            position={'sticky'}
                            bottom={0}
                            bgcolor={colors.white}
                        >
                            <Box display="flex">
                                <StyledTooltip
                                    placement="top"
                                    title={
                                        cloningDocument
                                            ? 'Clone to selected document'
                                            : movingDocument
                                            ? 'Move to selected Document'
                                            : 'Add new document to the selected document'
                                    }
                                >
                                    <span style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}>
                                        <Button
                                            fullWidth
                                            variant={'contained'}
                                            disabled={
                                                tree.length === 0 ||
                                                (selectedDocument && calculateDepth(selectedDocument, tree) > 5) ||
                                                readyFilter
                                            }
                                            onClick={() => {
                                                if (cloningDocument !== undefined && selectedDocument) {
                                                    const id = v4()
                                                    addDocument({
                                                        variables: {
                                                            document: cleanDocument(
                                                                {
                                                                    ...cloningDocument,
                                                                    id,
                                                                    parentId: selected,
                                                                    order: selectedDocument.documents.length,
                                                                },
                                                                true,
                                                            ),
                                                        },
                                                    })

                                                    setExpanded([...expanded, id])
                                                    setSelected(id)
                                                } else if (movingDocument !== undefined && selectedDocument) {
                                                    deleteDocument({
                                                        variables: {
                                                            document: cleanDocument(movingDocument),
                                                        },
                                                    })
                                                    const id = v4()
                                                    addDocument({
                                                        variables: {
                                                            document: cleanDocument(
                                                                {
                                                                    ...movingDocument,
                                                                    id,
                                                                    parentId: selected,
                                                                    order: movingDocument.documents.length,
                                                                },
                                                                true,
                                                            ),
                                                        },
                                                    })
                                                    setExpanded([...expanded, selected])
                                                    setSelected(id)
                                                    setMovingDocument(undefined)
                                                } else if (selectedDocument) {
                                                    const id = v4()
                                                    addDocument({
                                                        variables: {
                                                            document: cleanDocument({
                                                                id: id,
                                                                description: '',
                                                                items: [],
                                                                links: [],
                                                                title: 'New document',
                                                                parentId: selected,
                                                                order: selectedDocument.documents.length,
                                                                ready: selectedDocument.ready,
                                                            }),
                                                        },
                                                    })
                                                    setSelected(id)
                                                    setExpanded([...expanded, selected])
                                                }
                                            }}
                                        >
                                            {cloningDocument ? 'Clone to' : movingDocument ? 'Move to' : 'Add Document'}
                                        </Button>
                                    </span>
                                </StyledTooltip>
                                <StyledTooltip
                                    placement="top"
                                    title={
                                        cloningDocument
                                            ? 'Clone document in the same level'
                                            : movingDocument
                                            ? 'Move document to the same level'
                                            : 'Add new document in the same level'
                                    }
                                >
                                    <span style={{ flexBasis: 0, flexGrow: 1 }}>
                                        <Button
                                            fullWidth
                                            variant={'contained'}
                                            disabled={tree.length === 0 || readyFilter}
                                            onClick={() => {
                                                if (cloningDocument !== undefined && selectedDocument) {
                                                    const id = v4()
                                                    addDocument({
                                                        variables: {
                                                            document: cleanDocument(
                                                                {
                                                                    ...selectedDocument,
                                                                    id: id,
                                                                    parentId: parentDoc ? parentDoc.id : '',
                                                                    order: parentDoc
                                                                        ? parentDoc.documents.length
                                                                        : tree.length,
                                                                },
                                                                true,
                                                            ),
                                                        },
                                                    })
                                                } else if (movingDocument !== undefined && selectedDocument) {
                                                    deleteDocument({
                                                        variables: {
                                                            document: cleanDocument(movingDocument),
                                                        },
                                                    })
                                                    const id = v4()
                                                    addDocument({
                                                        variables: {
                                                            document: cleanDocument(
                                                                {
                                                                    ...movingDocument,
                                                                    id,
                                                                    parentId: parentDoc ? parentDoc.id : '',
                                                                    order: parentDoc
                                                                        ? parentDoc.documents.length
                                                                        : tree.length,
                                                                },
                                                                true,
                                                            ),
                                                        },
                                                    })
                                                    setExpanded([...expanded, parentDoc?.id ?? ''])
                                                    setSelected(id)
                                                    setMovingDocument(undefined)
                                                } else {
                                                    const id = v4()
                                                    addDocument({
                                                        variables: {
                                                            document: cleanDocument({
                                                                id: id,
                                                                description: '',
                                                                items: [],
                                                                links: [],
                                                                title: 'New document',
                                                                parentId: parentDoc ? parentDoc.id : '',
                                                                order: parentDoc
                                                                    ? parentDoc.documents.length
                                                                    : tree.length,
                                                                ready: false,
                                                            }),
                                                        },
                                                    })
                                                    setSelected(id)
                                                }
                                            }}
                                        >
                                            {cloningDocument
                                                ? 'Clone Sibling'
                                                : movingDocument
                                                ? 'Move Sibling'
                                                : 'Add Sibling'}
                                        </Button>
                                    </span>
                                </StyledTooltip>
                            </Box>
                            <StyledTooltip
                                placement="right"
                                title={
                                    cloningDocument
                                        ? 'Clone to root'
                                        : movingDocument
                                        ? 'Move to root'
                                        : 'Add new document to the root'
                                }
                            >
                                <span style={{ flexBasis: 0, flexGrow: 1, marginTop: 8 }}>
                                    <Button
                                        fullWidth
                                        variant={'outlined'}
                                        disabled={readyFilter}
                                        onClick={() => {
                                            if (cloningDocument !== undefined && selectedDocument) {
                                                const id = v4()
                                                addDocument({
                                                    variables: {
                                                        document: cleanDocument(
                                                            {
                                                                ...cloningDocument,
                                                                id,
                                                                parentId: '',
                                                                order: tree.length,
                                                            },
                                                            true,
                                                        ),
                                                    },
                                                })
                                                setExpanded([...expanded, id])
                                                setSelected(id)
                                            } else if (movingDocument !== undefined && selectedDocument) {
                                                deleteDocument({
                                                    variables: {
                                                        document: cleanDocument({
                                                            ...movingDocument,
                                                        }),
                                                    },
                                                })
                                                const id = v4()
                                                addDocument({
                                                    variables: {
                                                        document: cleanDocument(
                                                            {
                                                                ...movingDocument,
                                                                id,
                                                            },
                                                            true,
                                                        ),
                                                    },
                                                })
                                                setExpanded([...expanded, ''])
                                                setSelected(id)
                                                setMovingDocument(undefined)
                                            } else {
                                                const id = v4()
                                                addDocument({
                                                    variables: {
                                                        document: cleanDocument({
                                                            id,
                                                            description: '',
                                                            items: [],
                                                            links: [],
                                                            title: 'New document',
                                                            parentId: '',
                                                            order: tree.length,
                                                            ready: false,
                                                        }),
                                                    },
                                                })
                                                setSelected(id)
                                                setExpanded([...expanded, id])
                                            }
                                        }}
                                    >
                                        {cloningDocument
                                            ? 'Clone to Root'
                                            : movingDocument
                                            ? 'Move to Root'
                                            : 'Add to Root'}
                                    </Button>
                                </span>
                            </StyledTooltip>
                        </Box>
                    </Box>
                    <Box display="flex" flexBasis={0} flexGrow={3} flexDirection={'column'}>
                        {!editing && selectedDocument && (
                            <DocumentView
                                cloningDocument={cloningDocument}
                                setCloningDocument={setCloningDocument}
                                skillLevel={skillLevel}
                                ticks={data2.getTicks}
                                selectedDocument={selectedDocument}
                                onEditClick={() => {
                                    setSelectedDocument(selectedDocument)
                                    setEditing(!editing)
                                }}
                                userId={keycloak.tokenParsed?.sub ?? ''}
                                onDeleteClick={() => {
                                    selectedDocument &&
                                        deleteDocument({
                                            variables: {
                                                document: cleanDocument({
                                                    ...selectedDocument,
                                                }),
                                            },
                                        })
                                    setSelected(findSelected())
                                }}
                                open={open}
                                setOpen={setOpen}
                                movingDocument={movingDocument}
                                setMovingDocument={setMovingDocument}
                                onSetReady={(ready) =>
                                    confirmDocument({
                                        variables: {
                                            document: cleanDocument({
                                                ...selectedDocument,
                                                ready,
                                            }),
                                        },
                                    })
                                }
                            />
                        )}
                        {editing && selectedDocument && (
                            <DocumentEdit
                                save={(document) => saveDocument({ variables: { document: document } })}
                                close={() => setEditing(!editing)}
                                selectedDocument={selectedDocument}
                                document={editingDocument ?? selectedDocument}
                                setDocument={setEditingDocument}
                                setEditing={setEditing}
                                onLinkFocus={setLinkFocus}
                                onItemFocus={setItemFocus}
                                confirming={loadingConfirm}
                                saving={loadingSave}
                                confirm={(document) => confirmDocument({ variables: { document: document } })}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
