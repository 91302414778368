import { isAfter, isBefore } from 'date-fns'
import { SkillLevel } from 'graphql/types'

export type Order = 'asc' | 'desc'

export function getComparator<T, K extends keyof T>(c?: K, o?: Order, d?: keyof T[K]): (a: T, b: T) => 0 | 1 | -1 {
    return function (a: T, b: T): 0 | 1 | -1 {
        const asc = o === 'asc' ? 1 : -1
        const desc = o === 'desc' ? 1 : -1
        if (!c) return 0
        if (!o) return 0
        if (d && !b?.[c]?.[d] && !a?.[c]?.[d]) return 0
        if (d && !b?.[c]?.[d] && a[c][d]) return asc
        if (d && b[c][d] && !a?.[c]?.[d]) return desc
        if (d && typeof b[c][d] === 'boolean') return b[c][d] === a[c][d] ? 0 : b[c][d] ? asc : desc
        // @ts-expect-error: if b[c][d] is Date
        if (d && b[c][d] instanceof Date && isAfter(new Date(b[c][d]), new Date(a[c][d]))) return asc
        // @ts-expect-error: if b[c][d] is Date
        if (d && b[c][d] instanceof Date && isBefore(new Date(b[c][d]), new Date(a[c][d]))) return desc
        if (d && !isNaN(Number(b[c][d])) && Number(b[c][d]) < Number(a[c][d])) return asc
        if (d && !isNaN(Number(b[c][d])) && Number(b[c][d]) > Number(a[c][d])) return desc
        if (d && b[c][d] < a[c][d]) return asc
        if (d && b[c][d] > a[c][d]) return desc
        if (!d && !b?.[c] && !a?.[c]) return 0
        if (!d && !b?.[c] && a[c]) return asc
        if (!d && b[c] && !a?.[c]) return desc
        if (!d && typeof b[c] === 'boolean') return b[c] === a[c] ? 0 : b[c] ? asc : desc
        // @ts-expect-error: if b[c] is Date
        if (!d && b[c] instanceof Date && isAfter(new Date(b[c]), new Date(a[c]))) return asc
        // @ts-expect-error: if b[c] is Date
        if (!d && b[c] instanceof Date && isBefore(new Date(b[c]), new Date(a[c]))) return desc
        if (!d && !isNaN(Number(b[c])) && Number(b[c]) < Number(a[c])) return asc
        if (!d && !isNaN(Number(b[c])) && Number(b[c]) > Number(a[c])) return desc
        if (!d && b[c] < a[c]) return asc
        if (!d && b[c] > a[c]) return desc
        return 0
    }
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => 0 | 1 | -1): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, 0 | 1 | -1])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export const checkboxColor = (code: number, requirement: SkillLevel): string => {
    const value = code
    if (requirement === SkillLevel.JUNIOR) {
        if (value < 1) return 'red'
        else if (value < 4) return 'gold'
        else return 'green'
    } else if (requirement === SkillLevel.ESTABLISHED) {
        if (value === 0 || value === 1 || value === 4) return 'red'
        else if (value > 6) return 'green'
        else return 'gold'
    } else if (requirement === SkillLevel.SENIOR) {
        if (value === 9) return 'green'
        else if (value === 3 || value === 6 || value === 8) return 'gold'
        else return 'red'
    }
    return 'black'
}
