import gql from 'graphql-tag'

export default gql`
    query getTicks($userId: ID!) {
        getTicks(userId: $userId) {
            id
            itemId
            userId
        }
    }
`
