import Keycloak from 'keycloak-js'
import getEnvConfig from 'utils/getEnvConfig'

const keycloak = (): Keycloak.KeycloakInstance => {
    const envConfig = getEnvConfig()
    return Keycloak({
        url: envConfig.Keycloak.URL + '/auth/',
        realm: envConfig.Keycloak.Realm,
        clientId: envConfig.Keycloak.ClientID,
    })
}

export default keycloak
