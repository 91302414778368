import gql from 'graphql-tag'

export default gql`
    query getDefaultSkillMap {
        getDefaultSkillMap {
            id
            defaultSkillMap
        }
    }
`
