import { Close } from '@mui/icons-material'
import { Box, Button, Drawer, IconButton, TextField, Typography } from '@mui/material'
import { drawerWidth } from 'pages/MapPage/useMapPageUtil'
import { DrawerNoteNodeStateReturn } from './useDrawerNoteNode'

export const DrawerNoteNode = (props: DrawerNoteNodeStateReturn): JSX.Element => {
    const { setOpen, note, editNote, open, setNote, addNote, editId, setEditId } = props

    return (
        <Drawer anchor="right" open={open} variant={'temporary'} ModalProps={{ onClose: () => setOpen(false) }}>
            <Box display={'flex'} flexDirection={'column'} width={drawerWidth} flex={1}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={2}>
                    <Typography marginRight={1} variant={'h4'}>
                        {!editId ? 'Add Note' : 'Edit Note'}
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setOpen(false)
                            setEditId(undefined)
                            setNote('')
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                <Box display={'flex'} flexDirection={'column'} padding={2}>
                    <TextField
                        label={'Content'}
                        variant={'outlined'}
                        style={{ marginBottom: 8 }}
                        value={note}
                        onChange={(event) => {
                            setNote(event.target.value)
                            if (!!editId) editNote(event.target.value)
                        }}
                    />

                    {!editId && (
                        <Button variant={'contained'} onClick={() => addNote(note)} style={{ marginTop: 8 }}>
                            Add
                        </Button>
                    )}
                </Box>
            </Box>
        </Drawer>
    )
}
