import { SkillLevel, Tick } from 'graphql/types'
import { Document } from 'utils/tree'

export type CountResult = {
    optional: number
    required: number
    recommended: number
}

export const countUncheckedItems = (
    documents: Document[],
    skillLevel: SkillLevel,
    ticks: Tick[],
    readyFilter: boolean,
): CountResult => {
    const counts: CountResult = {
        optional: 0,
        required: 0,
        recommended: 0,
    }

    documents
        .filter((d) => !readyFilter || d.ready)
        .forEach((document) => {
            const c = countUncheckedItems(document.documents, skillLevel, ticks, readyFilter)
            counts.optional += c.optional
            counts.recommended += c.recommended
            counts.required += c.required

            document.items.forEach((item) => {
                if (ticks.some((tick) => tick.itemId === item.id)) return

                const value = item.requirements
                if (skillLevel === SkillLevel.JUNIOR) {
                    if (value < 1) counts.required++
                    else if (value < 4) counts.recommended++
                    else counts.optional++
                } else if (skillLevel === SkillLevel.ESTABLISHED) {
                    if (value === 0 || value === 1 || value === 4) counts.required++
                    else if (value > 6) counts.optional++
                    else counts.recommended++
                } else if (skillLevel === SkillLevel.SENIOR) {
                    if (value === 9) counts.optional++
                    else if (value === 3 || value === 6 || value === 8) counts.recommended++
                    else counts.required++
                }
            })
        })

    return counts
}

export const countTotalItems = (documents: Document[], skillLevel: SkillLevel, readyFilter: boolean): CountResult => {
    const counts: CountResult = {
        optional: 0,
        required: 0,
        recommended: 0,
    }

    documents
        .filter((d) => !readyFilter || d.ready)
        .forEach((document) => {
            const c = countTotalItems(document.documents, skillLevel, readyFilter)
            counts.optional += c.optional
            counts.recommended += c.recommended
            counts.required += c.required

            document.items.forEach((item) => {
                const value = item.requirements
                if (skillLevel === SkillLevel.JUNIOR) {
                    if (value < 1) counts.required++
                    else if (value < 4) counts.recommended++
                    else counts.optional++
                } else if (skillLevel === SkillLevel.ESTABLISHED) {
                    if (value === 0 || value === 1 || value === 4) counts.required++
                    else if (value > 6) counts.optional++
                    else counts.recommended++
                } else if (skillLevel === SkillLevel.SENIOR) {
                    if (value === 9) counts.optional++
                    else if (value === 3 || value === 6 || value === 8) counts.recommended++
                    else counts.required++
                }
            })
        })

    return counts
}
