import { useMutation } from '@apollo/client'
import { ArrowForward, Delete, Edit, FileCopy } from '@mui/icons-material'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Switch,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { StyledTooltip } from 'components/StyledTooltip/StyledTooltip'
import Tick from 'graphql/mutations/Tick'
import Untick from 'graphql/mutations/Untick'
import GetTicks from 'graphql/queries/GetTicks'
import { SkillLevel, Tick as TickType, TickInput } from 'graphql/types'
import { cloneDeep } from 'lodash'
import ReactMarkdown from 'react-markdown'
import colors from 'utils/colors'
import { checkboxColor } from 'utils/functions/functions'
import { Document } from 'utils/tree'
import { v4 } from 'uuid'

export interface DocumentViewProps {
    selectedDocument: Document
    skillLevel: SkillLevel
    ticks: TickType[]
    userId: string
    onEditClick?: () => void
    onSetReady?: (ready: boolean) => void
    setCloningDocument?: (document: Document | undefined) => void
    setMovingDocument?: (document: Document | undefined) => void
    open?: boolean
    setOpen?: (open: boolean) => void
    cloningDocument?: Document | undefined
    movingDocument?: Document | undefined
    onDeleteClick?: () => void
}

export const DocumentView = (props: DocumentViewProps): JSX.Element => {
    const {
        selectedDocument,
        onEditClick,
        onDeleteClick,
        ticks,
        userId,
        skillLevel,
        cloningDocument,
        setCloningDocument,
        movingDocument,
        setMovingDocument,
        open,
        setOpen,
        onSetReady,
    } = props

    const [tick] = useMutation(Tick, {
        update(cache, { data }) {
            const currentCache: { getTicks: TickType[] } | null = cache.readQuery({
                query: GetTicks,
                variables: {
                    userId,
                },
            })
            if (currentCache === null) return
            cache.writeQuery({
                query: GetTicks,
                data: {
                    getTicks: currentCache.getTicks.concat(data.tick),
                },
                variables: {
                    userId,
                },
            })
        },
    })
    const [untick] = useMutation(Untick, {
        update(cache, { data }) {
            // https://github.com/apollographql/apollo-client/issues/6451
            cache.modify({
                fields: {
                    getTicks(list, { readField }) {
                        return list.filter((n: any) => readField('id', n) !== data.untick.id)
                    },
                },
            })
        },
    })
    if (selectedDocument === undefined) return <Typography>Error</Typography>
    const filteredItems = cloneDeep(selectedDocument.items)
    filteredItems.sort((a, b) => {
        const colorA = checkboxColor(a.requirements, skillLevel)
        const colorB = checkboxColor(b.requirements, skillLevel)
        if (colorA === colorB) return 0
        else if (colorA === 'red') return -1
        else if (colorB === 'red') return 1
        else if (colorA === 'gold') return -1
        else return 1
    })
    return (
        <>
            <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} padding={2} paddingBottom={0}>
                <Typography variant={'h1'}>{selectedDocument.title}</Typography>
                <StyledTooltip title={'Is this document ready?'}>
                    <Switch
                        sx={{ marginTop: 1, marginLeft: 1 }}
                        checked={selectedDocument.ready}
                        onChange={(event) => {
                            onSetReady ? onSetReady(event.target.checked) : undefined
                        }}
                    />
                </StyledTooltip>
                <StyledTooltip title="Edit document">
                    <IconButton
                        sx={{
                            marginLeft: 'auto',
                            '&:hover': {
                                color: colors.main,
                            },
                        }}
                        onClick={() => {
                            onEditClick ? onEditClick() : undefined
                            setCloningDocument ? setCloningDocument(undefined) : undefined
                            setMovingDocument ? setMovingDocument(undefined) : undefined
                        }}
                    >
                        <Edit />
                    </IconButton>
                </StyledTooltip>

                <StyledTooltip title={cloningDocument ? 'Stop Clone Mode' : 'Start Clone Mode'}>
                    <IconButton
                        sx={{
                            '&:hover': {
                                color: colors.main,
                            },
                        }}
                        onClick={() => {
                            setCloningDocument
                                ? setCloningDocument(cloningDocument ? undefined : selectedDocument)
                                : undefined
                            setMovingDocument ? setMovingDocument(undefined) : undefined
                        }}
                    >
                        <FileCopy sx={{ fill: cloningDocument ? colors.main : undefined }} />
                    </IconButton>
                </StyledTooltip>

                <StyledTooltip title={movingDocument ? 'Stop Move Mode' : 'Start Move Mode'}>
                    <IconButton
                        sx={{
                            '&:hover': {
                                color: colors.main,
                            },
                        }}
                        onClick={() => {
                            setMovingDocument
                                ? setMovingDocument(movingDocument ? undefined : selectedDocument)
                                : undefined
                            setCloningDocument ? setCloningDocument(undefined) : undefined
                        }}
                    >
                        <ArrowForward sx={{ fill: movingDocument ? colors.main : undefined }} />
                    </IconButton>
                </StyledTooltip>

                <StyledTooltip title="Delete document">
                    <IconButton
                        sx={{
                            '&:hover': {
                                color: colors.main,
                            },
                        }}
                        onClick={() => (setOpen ? setOpen(true) : undefined)}
                    >
                        <Delete />
                    </IconButton>
                </StyledTooltip>
            </Box>
            <Box
                display="flex"
                flexDirection={'column'}
                padding={2}
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
                paddingTop={0}
            >
                <Box>
                    <ReactMarkdown rawSourcePos>{selectedDocument.description}</ReactMarkdown>
                </Box>
                {selectedDocument.links.length > 0 && (
                    <>
                        <Divider style={{ marginTop: 0, marginBottom: 16 }} />
                        <Typography variant={'h3'}>Where to learn:</Typography>
                        <Box display={'flex'} flexWrap={'wrap'} marginTop={1} flexDirection={'column'}>
                            {selectedDocument.links.map((link) => (
                                <Box key={link.id}>
                                    <StyledTooltip title={link.url}>
                                        <Button
                                            sx={{
                                                textDecoration: 'underline',
                                                justifyContent: 'flex-start',
                                                minWidth: 0,
                                            }}
                                            href={
                                                link.url.includes('https://') || link.url.includes('http://')
                                                    ? link.url
                                                    : link.url.includes('https://')
                                                    ? `https://` + link.url
                                                    : `http://` + link.url
                                            }
                                            target={'_blank'}
                                        >
                                            {link.label}
                                        </Button>
                                    </StyledTooltip>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
                {filteredItems.length > 0 && (
                    <>
                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                        <Typography variant={'h3'}>Skillset checklist:</Typography>
                        {filteredItems.map((item) => (
                            <Box key={item.id} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <Checkbox
                                    style={{ color: checkboxColor(item.requirements, skillLevel) }}
                                    checked={ticks.some((tick) => tick.itemId === item.id)}
                                    onChange={(event) => {
                                        if (event.target.checked)
                                            tick({
                                                variables: {
                                                    tick: {
                                                        id: v4(),
                                                        itemId: item.id,
                                                        userId,
                                                    } as TickInput,
                                                },
                                            })
                                        else
                                            untick({
                                                variables: {
                                                    tick: {
                                                        id: ticks.find((tick) => tick.itemId === item.id)?.id ?? '',
                                                        itemId: item.id,
                                                        userId,
                                                    } as TickInput,
                                                },
                                            })
                                    }}
                                />
                                <Typography>{item.title}</Typography>
                            </Box>
                        ))}
                    </>
                )}
                <Dialog
                    open={open ?? false}
                    onClose={() => (setOpen ? setOpen(false) : undefined)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: 24, color: colors.main }}>
                        {'Delete the document?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
                            When a document is deleted, the documents inside will be moved one level up. All the other
                            data will be deleted
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'outlined'} onClick={() => (setOpen ? setOpen(false) : undefined)}>
                            No
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setOpen ? setOpen(false) : undefined
                                onDeleteClick ? onDeleteClick() : undefined
                            }}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}
