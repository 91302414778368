import gql from 'graphql-tag'

export default gql`
    mutation EditDefaultSkillMap($defaultSkillMap: DefaultSkillMapInput!) {
        editDefaultSkillMap(defaultSkillMap: $defaultSkillMap) {
            id
            defaultSkillMap
        }
    }
`
