import { DrawerDocumentStateReturn } from 'components/DrawerDocument/useDrawerDocument'
import { DrawerImageNodeStateReturn } from 'components/DrawerImageNode/useDrawerImageNode'
import { DrawerMapNodeStateReturn } from 'components/DrawerMapNode/useDrawerMapNode'
import { DrawerNoteNodeStateReturn } from 'components/DrawerNoteNode/useDrawerNoteNode'
import { NodeType } from 'graphql/types'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { Connection, Edge, FlowElement, Node } from 'react-flow-renderer'
import { cleanSkillMap } from 'utils/types'
import { createEdges, createNode } from './FlowElements'
import { MapPageApolloReturn } from './useMapPageApollo'
import { MapPageNavigationReturn } from './useMapPageNavigation'
import { MapPageUtilStateReturn } from './useMapPageUtil'

export interface MapPageFlowStateReturn {
    elements: FlowElement[]
    onLoad: (rf: any) => void
    onNodeDoubleClick: (event: React.MouseEvent<Element, MouseEvent>, element: FlowElement) => void
    onElementsRemove: (elements: FlowElement[]) => void
    onConnect: (connection: Edge | Connection) => void
    onNodeDragStop: (event: React.MouseEvent<Element, MouseEvent>, node: Node) => void
    onNodeContextMenu: (event: React.MouseEvent<Element, MouseEvent>, node: Node) => void
    onSelectionDragStop: (event: React.MouseEvent<Element, MouseEvent>, nodes: Node[]) => void
}

export const useMapPageFlow = (
    apollo: MapPageApolloReturn,
    navigation: MapPageNavigationReturn,
    util: MapPageUtilStateReturn,
    drawerDocument: DrawerDocumentStateReturn,
    drawerImageNode: DrawerImageNodeStateReturn,
    drawerMapNode: DrawerMapNodeStateReturn,
    drawerNoteNode: DrawerNoteNodeStateReturn,
): MapPageFlowStateReturn => {
    const { tree, skillMaps, editSkillMap } = apollo
    const { skillMap, setNavigation, navigation: navigationArray } = navigation
    const { openDrawer } = util

    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null)

    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView()
        }
    }, [reactFlowInstance])

    const elements: FlowElement[] = [
        ...skillMap.skillNodes.map((node) => createNode(node, tree, skillMaps)),
        ...skillMap.skillNodes.flatMap(createEdges),
    ]

    const onSelectionDragStop = (_: React.MouseEvent<Element, MouseEvent>, nodes: Node[]) => {
        const skillNodes = cloneDeep(skillMap.skillNodes)

        nodes.forEach((element) => {
            const index = skillMap.skillNodes.findIndex((node) => node.id === element.id)
            if (index !== -1)
                skillNodes[index] = {
                    ...skillNodes[index],
                    x: Math.round(element.position.x),
                    y: Math.round(element.position.y),
                }
        })
        editSkillMap({
            skillMap: cleanSkillMap({
                ...skillMap,
                skillNodes,
            }),
        })
    }

    const onNodeDragStop = (event: React.MouseEvent<Element, MouseEvent>, node: Node) =>
        onSelectionDragStop(event, [node])

    const onConnect = (connection: Edge | Connection) => {
        const index = skillMap.skillNodes.findIndex((node) => node.id === connection.source)

        if (index !== -1 && connection.target) {
            editSkillMap({
                skillMap: cleanSkillMap({
                    ...skillMap,
                    skillNodes: [
                        ...skillMap.skillNodes.slice(0, index),
                        {
                            ...skillMap.skillNodes[index],
                            connections: [...skillMap.skillNodes[index].connections, connection.target],
                        },
                        ...skillMap.skillNodes.slice(index + 1),
                    ],
                }),
            })
        }
    }
    const onNodeDoubleClick = (_: any, element: FlowElement) => {
        const skillNode = skillMap.skillNodes.find((node) => node.id === element.id)
        if (skillNode !== undefined) {
            if (skillNode.nodeType === NodeType.MAP) setNavigation([...navigationArray, skillNode.content])
            else if (skillNode.nodeType === NodeType.DOCUMENT) {
                const foundDocument = tree.find((doc) => doc.id == skillNode.content)
                if (foundDocument) {
                    drawerDocument.setDocument(foundDocument)
                    openDrawer('document')
                }
            } else if (skillNode.nodeType === NodeType.NOTE) {
                drawerNoteNode.setEditId(skillNode.id)
                drawerNoteNode.setNote(skillNode.content)
                openDrawer('noteNode')
            } else {
                drawerImageNode.setEditId(skillNode.id)
                drawerImageNode.setSource(skillNode.content)
                openDrawer('imageNode')
            }
        }
    }
    const onElementsRemove = (elements: FlowElement[]) => {
        const copy = cloneDeep(skillMap)

        elements.forEach((element) => {
            const node = element as Node
            const edge = element as Edge

            if (!element.id.startsWith('edgeFrom_')) {
                // remove the node itself
                const index = copy.skillNodes.findIndex((skillNode) => skillNode.id === node.id)
                if (index !== -1) copy.skillNodes.splice(index, 1)

                // Remove every connection that linked to that node
                copy.skillNodes.forEach((skillNode) => {
                    skillNode.connections = skillNode.connections.filter((connection) => connection !== node.id)
                })
            } else {
                const index = copy.skillNodes.findIndex((skillNode) => skillNode.id === edge.source)
                if (index !== -1) {
                    const connectionIndex = skillMap.skillNodes[index].connections.findIndex(
                        (conn) => conn === edge.target,
                    )
                    if (connectionIndex !== -1) copy.skillNodes[index].connections.splice(connectionIndex, 1)
                }
            }
        })

        editSkillMap({ skillMap: cleanSkillMap(copy) })
    }

    const onNodeContextMenu = (event: React.MouseEvent<Element, MouseEvent>, node: Node) => {
        event.preventDefault()
        const foundNode = skillMap.skillNodes.find(
            (skillNode) => skillNode.nodeType === NodeType.MAP && skillNode.id === node.id,
        )
        if (!foundNode) return

        const foundSkillMap = skillMaps.find((map) => map.id === foundNode.content)

        if (foundSkillMap) {
            drawerMapNode.setSkillMap(foundSkillMap)
            drawerMapNode.setEditing(true)
            openDrawer('mapNode')
        }
    }

    return {
        elements,
        onConnect,
        onElementsRemove,
        onNodeDoubleClick,
        onNodeDragStop,
        onNodeContextMenu: onNodeContextMenu,
        onLoad: setReactFlowInstance,
        onSelectionDragStop,
    }
}
