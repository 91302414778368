import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import * as React from 'react'
import colors from 'utils/colors'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.mainTooltip,
        color: colors.white,
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))
