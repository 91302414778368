import { createTheme, responsiveFontSizes, Theme } from '@mui/material'
import colors from './colors'
import fontStyles from './fontStyles'

// const breakpoints = createBreakpoints({})
// export const smallMobile = 385
// export const smallestMobile = 360

const theme: Theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.ExampleText': {
                        textAlign: 'center',
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    '&.LoadingSpinner': {
                        zIndex: 1400,
                        color: '#fff',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&.MuiSelect-select': {
                        color: 'red',
                    },

                    // '.MuiSelect-select': {
                    //     '& .MuiInputBase-input': {
                    //         color: '#fff',
                    //     },
                    // },
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    '&.LoadingSpinner': {
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.ErrorBox': {
                        padding: 20,
                        marginTop: 20,
                        width: '100%',
                        borderRadius: 4,
                        backgroundColor: colors.lightWarning,
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.ErrorBoxListItem': {
                        color: colors.warning,
                    },
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    '&.ErrorBox': {
                        color: colors.warning,
                        fontSize: '1.375rem',
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    '&.ErrorBox': {
                        height: '7px',
                        width: '7px',
                        minWidth: 28,
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '&.ErrorBox': {
                        height: '7px',
                        width: '7px',
                        color: colors.warning,
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.mainContainer': {
                        background: '#fff',
                        padding: '24px',
                    },
                },
            },
        },
        // MuiTreeItem: {
        //     styleOverrides: {
        //         label: {
        //             padding: '10px 16px',
        //             paddingLeft: '16px',
        //         },
        //     },
        // },
    },
    palette: {
        primary: {
            main: colors.main,
        },
        secondary: {
            main: colors.main,
        },
        text: {
            disabled: colors.midGray,
            primary: colors.black,
            secondary: colors.main,
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Roboto Condensed',
        h1: { ...fontStyles.h1 },
        h2: { ...fontStyles.h2 },
        h3: { ...fontStyles.h3 },
        h4: { ...fontStyles.h4 },
        h5: { ...fontStyles.h5 },
        h6: { ...fontStyles.h6 },
        body1: { ...fontStyles.body1 },
        body2: { ...fontStyles.body2 },
        subtitle1: { ...fontStyles.subtitle1 },
        subtitle2: { ...fontStyles.subtitle2 },
        button: { ...fontStyles.button },
    },
})

export const ModifiedTheme = responsiveFontSizes(theme)
