import { Add, Delete, DragIndicator } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { StyledTooltip } from 'components/StyledTooltip/StyledTooltip'
import { DocumentInput, Item, Link } from 'graphql/types'
import { cloneDeep } from 'lodash'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd'
import colors from 'utils/colors'
import { Document } from 'utils/tree'
import { v4 } from 'uuid'

export interface DocumentEditProps {
    close: () => void
    save: (document: DocumentInput) => void
    confirm: (document: DocumentInput) => void
    document: Document
    selectedDocument: Document
    setDocument: (document: Document) => void
    setEditing: (editing: boolean) => void
    onItemFocus: (item: Item | undefined) => void
    onLinkFocus: (link: Link | undefined) => void
    saving: boolean
    confirming: boolean
}

const onDragEnd = (result: DropResult, setDocument: (document: Document) => void, document: Document) => {
    const { destination, source } = result
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return
    if (destination.droppableId !== source.droppableId) return
    if (source.droppableId === 'Items') {
        const copy = cloneDeep(document)
        const item = copy.items.splice(source.index, 1)
        copy.items.splice(destination.index, 0, ...item)
        setDocument(copy)
    }
    if (source.droppableId === 'Links') {
        const copy = cloneDeep(document)
        const link = copy.links.splice(source.index, 1)
        copy.links.splice(destination.index, 0, ...link)
        setDocument(copy)
    }
}

export const DocumentEdit = (props: DocumentEditProps): JSX.Element => {
    const {
        close,
        save,
        confirm,
        document: documentItem,
        setDocument,
        selectedDocument,
        onItemFocus,
        onLinkFocus,
        saving,
        confirming,
    } = props

    return (
        <DragDropContext onDragEnd={(e) => onDragEnd(e, setDocument, documentItem)}>
            <Box
                display="flex"
                flexDirection={'column'}
                padding={2}
                flex={1}
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
            >
                <TextField
                    autoFocus
                    label="Title"
                    value={documentItem.title}
                    onChange={(event) => setDocument({ ...documentItem, title: event.target.value })}
                    sx={{ marginBottom: 1 }}
                />

                <TextField
                    label="Description"
                    multiline
                    value={documentItem.description}
                    onChange={(event) => setDocument({ ...documentItem, description: event.target.value })}
                    sx={{ marginBottom: 1 }}
                />
                <>
                    <Typography variant={'h3'} marginTop={2}>
                        Links:
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Droppable droppableId={'Links'}>
                            {(provided: DroppableProvided): JSX.Element => (
                                <span {...provided.droppableProps} ref={provided.innerRef}>
                                    {documentItem.links.map((link, index) => (
                                        <Draggable key={link.id} draggableId={link.id} index={index}>
                                            {(provided: DraggableProvided): JSX.Element => (
                                                <div
                                                    key={link.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Box
                                                        key={link.id}
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                        marginBottom={index === documentItem.links.length - 1 ? 0 : 1}
                                                        marginTop={index === 0 ? 1 : 0}
                                                    >
                                                        <DragIndicator sx={{ marginRight: 1 }} />
                                                        <TextField
                                                            id={'l' + index}
                                                            fullWidth
                                                            label="Link name"
                                                            value={link.label}
                                                            onChange={(event) => {
                                                                const copy = cloneDeep(documentItem)
                                                                copy.links[index].label = event.target.value
                                                                setDocument(copy)
                                                            }}
                                                            sx={{ marginRight: 1 }}
                                                            onFocus={() => onLinkFocus(link)}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            label="Link URL"
                                                            value={link.url}
                                                            onChange={(event) => {
                                                                const copy = cloneDeep(documentItem)
                                                                copy.links[index].url = event.target.value
                                                                setDocument(copy)
                                                            }}
                                                            onFocus={() => onLinkFocus(link)}
                                                        />
                                                        <IconButton
                                                            sx={{
                                                                '&:hover': {
                                                                    color: colors.main,
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                const copy = cloneDeep(documentItem)
                                                                copy.links.splice(index, 1)
                                                                setDocument(copy)
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Box>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </span>
                            )}
                        </Droppable>

                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() => {
                                const copy = cloneDeep(documentItem)
                                copy.links.push({ id: v4(), url: '', label: '' })
                                setDocument(copy)
                                setTimeout(() => {
                                    const element = document.getElementById(`l${copy.links.length - 1}`)
                                    if (element) {
                                        element.focus()
                                    }
                                }, 100)
                            }}
                            sx={{ marginTop: 1, maxWidth: 'fit-content', paddingRight: 2 }}
                        >
                            Add new link
                        </Button>
                    </Box>
                </>

                <>
                    <Typography variant={'h3'} marginTop={2}>
                        Assessment:
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Droppable droppableId={'Items'}>
                            {(provided: DroppableProvided): JSX.Element => (
                                <span {...provided.droppableProps} ref={provided.innerRef}>
                                    {documentItem.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided: DraggableProvided): JSX.Element => (
                                                <div
                                                    id={item.id}
                                                    key={item.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                        marginBottom={index === documentItem.items.length - 1 ? 0 : 1}
                                                        marginTop={index === 0 ? 1 : 0}
                                                    >
                                                        <DragIndicator sx={{ marginRight: 1 }} />
                                                        <TextField
                                                            id={'c' + index}
                                                            label="Checkbox title"
                                                            fullWidth
                                                            value={item.title}
                                                            onChange={(event) => {
                                                                const copy = cloneDeep(documentItem)
                                                                copy.items[index].title = event.target.value
                                                                setDocument(copy)
                                                            }}
                                                            onFocus={() => onItemFocus(item)}
                                                        />
                                                        <FormControl sx={{ marginLeft: 1, minWidth: 210 }}>
                                                            <InputLabel>Requirements</InputLabel>
                                                            <Select
                                                                onKeyPress={(e) => {
                                                                    const clone = cloneDeep(documentItem.items)
                                                                    clone[index].requirements = Number(e.code.slice(-1))
                                                                    setDocument({ ...documentItem, items: clone })
                                                                }}
                                                                variant={'outlined'}
                                                                value={item.requirements}
                                                                label="Requirements"
                                                                onChange={(event) => {
                                                                    const clone = cloneDeep(documentItem.items)
                                                                    clone[index].requirements = Number(
                                                                        event.target.value,
                                                                    )
                                                                    setDocument({ ...documentItem, items: clone })
                                                                }}
                                                            >
                                                                <MenuItem value={0}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={1}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={2}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={3}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={4}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={5}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={6}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={7}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'red'}
                                                                            padding={0.5}
                                                                        >
                                                                            Req
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={8}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography bgcolor={'gold'} padding={0.5}>
                                                                            Rec
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem value={9}>
                                                                    <Box display={'flex'} alignItems={'center'}>
                                                                        J:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; E:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                        ; S:&nbsp;
                                                                        <Typography
                                                                            color="white"
                                                                            bgcolor={'green'}
                                                                            padding={0.5}
                                                                        >
                                                                            Opt
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <IconButton
                                                            sx={{
                                                                '&:hover': {
                                                                    color: colors.main,
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                const copy = cloneDeep(documentItem)
                                                                copy.items.splice(index, 1)
                                                                setDocument(copy)
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Box>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </span>
                            )}
                        </Droppable>

                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() => {
                                const copy = cloneDeep(documentItem)
                                copy.items.push({
                                    id: v4(),
                                    title: '',
                                    createdAt: new Date().toISOString(),
                                    lastEdit: new Date().toISOString(),
                                    requirements: 9,
                                })
                                setDocument(copy)
                                setTimeout(() => {
                                    const element = document.getElementById(`c${copy.items.length - 1}`)
                                    if (element) {
                                        element.focus()
                                    }
                                }, 100)
                            }}
                            sx={{ marginTop: 1, maxWidth: 'fit-content', paddingRight: 2 }}
                        >
                            Add new item
                        </Button>
                    </Box>
                </>
            </Box>
            <Box display={'flex'} sx={{ marginLeft: 'auto', marginTop: 'auto' }} alignItems={'center'} padding={1}>
                {(documentItem.title === '' ||
                    documentItem.items.some((item) => item.title === '') ||
                    documentItem.links.some((link) => link.label === '') ||
                    documentItem.links.some((link) => link.url === '')) && (
                    <Box display={'flex'}>
                        <Typography variant={'subtitle1'} color={'red'}>
                            All fields but
                        </Typography>
                        <Typography variant={'subtitle1'} color={'red'} marginX={0.5} fontWeight={'bold'}>
                            Description
                        </Typography>
                        <Typography variant={'subtitle1'} color={'red'} marginRight={1}>
                            are mandatory and must be filled to enable save button
                        </Typography>
                    </Box>
                )}
                <StyledTooltip title="Cancel changes and exit edit mode">
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setDocument(selectedDocument)
                            close()
                        }}
                        sx={{ width: 100 }}
                    >
                        Cancel
                    </Button>
                </StyledTooltip>
                <StyledTooltip title="Confirm changes for the current document">
                    <LoadingButton
                        variant={'contained'}
                        disabled={
                            documentItem.title === '' ||
                            documentItem.items.some((item) => item.title === '') ||
                            documentItem.links.some((link) => link.label === '') ||
                            documentItem.links.some((link) => link.url === '')
                        }
                        loadingPosition={'start'}
                        loading={confirming || saving}
                        onClick={() => {
                            confirm({
                                id: documentItem.id,
                                parentId: documentItem.parentId,
                                order: documentItem.order,
                                title: documentItem.title,
                                description: documentItem.description,
                                items: documentItem.items.map((item) => {
                                    return {
                                        id: item.id,
                                        createdAt: item.createdAt,
                                        lastEdit: item.lastEdit,
                                        requirements: item.requirements,
                                        title: item.title,
                                    }
                                }),
                                links: documentItem.links.map((link) => ({
                                    id: link.id,
                                    label: link.label,
                                    url: link.url,
                                    comment: link.comment,
                                })),
                                ready: documentItem.ready,
                            })
                        }}
                        sx={{ width: 100, marginLeft: 1 }}
                    >
                        <Box style={{ marginLeft: confirming ? 16 : 0 }}>{confirming ? 'Saving' : 'Confirm'}</Box>
                    </LoadingButton>
                </StyledTooltip>
                <StyledTooltip title="Save changes for the current document and exit Edit Mode">
                    <LoadingButton
                        variant={'contained'}
                        disabled={
                            documentItem.title === '' ||
                            documentItem.items.some((item) => item.title === '') ||
                            documentItem.links.some((link) => link.label === '') ||
                            documentItem.links.some((link) => link.url === '')
                        }
                        loadingPosition={'start'}
                        loading={saving || confirming}
                        onClick={() => {
                            save({
                                id: documentItem.id,
                                parentId: documentItem.parentId,
                                order: documentItem.order,
                                title: documentItem.title,
                                description: documentItem.description,
                                items: documentItem.items.map((item) => {
                                    return {
                                        id: item.id,
                                        createdAt: item.createdAt,
                                        lastEdit: item.lastEdit,
                                        requirements: item.requirements,
                                        title: item.title,
                                    }
                                }),
                                links: documentItem.links.map((link) => ({
                                    id: link.id,
                                    label: link.label,
                                    url: link.url,
                                    comment: link.comment,
                                })),
                                ready: documentItem.ready,
                            })
                        }}
                        sx={{ width: 100, marginLeft: 1 }}
                    >
                        <Box style={{ marginLeft: saving ? 16 : 0 }}>{saving ? 'Saving' : 'Save'}</Box>
                    </LoadingButton>
                </StyledTooltip>
            </Box>
        </DragDropContext>
    )
}
