import { Document, NodeType } from 'graphql/types'
import { MapPageApolloReturn } from 'pages/MapPage/useMapPageApollo'
import { MapPageNavigationReturn } from 'pages/MapPage/useMapPageNavigation'
import { useState } from 'react'
import { cleanSkillMap } from 'utils/types'
import { v4 } from 'uuid'

export interface DrawerDocumentNodeStateReturn {
    open: boolean
    setOpen: (open: boolean) => void
    search: string
    setSearch: (search: string) => void
    searchResults: Document[]
    addDocumentNode: (document: Document) => void
}

export const useDrawerDocumentNode = (
    apollo: MapPageApolloReturn,
    navigation: MapPageNavigationReturn,
): DrawerDocumentNodeStateReturn => {
    const { tree, editSkillMap } = apollo
    const { skillMap, setCenter } = navigation

    const [open, setOpen] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')

    const searchResults = tree.filter((doc) => doc.title.toLowerCase().includes(search.toLowerCase()))

    const addDocumentNode = (document: Document) => {
        setCenter(0, 0)
        editSkillMap({
            skillMap: cleanSkillMap({
                ...skillMap,
                skillNodes: [
                    ...skillMap.skillNodes,
                    {
                        id: v4(),
                        connections: [],
                        content: document.id,
                        nodeType: NodeType.DOCUMENT,
                        x: 0,
                        y: 0,
                        options: '',
                    },
                ],
            }),
        })
    }

    return {
        open,
        setOpen,
        search,
        setSearch,
        searchResults,
        addDocumentNode,
    }
}
