export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
}

export type DefaultSkillMap = {
    __typename?: 'DefaultSkillMap'
    id: Scalars['ID']
    defaultSkillMap: Scalars['String']
}

export type DefaultSkillMapInput = {
    id: Scalars['ID']
    defaultSkillMap: Scalars['String']
}

export type Document = {
    __typename?: 'Document'
    id: Scalars['ID']
    parentId: Scalars['ID']
    title: Scalars['String']
    description: Scalars['String']
    links: Array<Link>
    items: Array<Item>
    order: Scalars['Int']
    ready: Scalars['Boolean']
}

export type DocumentInput = {
    id: Scalars['ID']
    parentId: Scalars['ID']
    title: Scalars['String']
    description: Scalars['String']
    links: Array<LinkInput>
    items: Array<ItemInput>
    order: Scalars['Int']
    ready: Scalars['Boolean']
}

export type Item = {
    __typename?: 'Item'
    id: Scalars['ID']
    title: Scalars['String']
    requirements: Scalars['Int']
    createdAt: Scalars['String']
    lastEdit: Scalars['String']
}

export type ItemInput = {
    id: Scalars['ID']
    title: Scalars['String']
    requirements: Scalars['Int']
    createdAt: Scalars['String']
    lastEdit: Scalars['String']
}

export type Link = {
    __typename?: 'Link'
    id: Scalars['ID']
    url: Scalars['String']
    label: Scalars['String']
    comment?: Maybe<Scalars['String']>
}

export type LinkInput = {
    id: Scalars['ID']
    url: Scalars['String']
    label: Scalars['String']
    comment?: Maybe<Scalars['String']>
}

export type Mutation = {
    __typename?: 'Mutation'
    addDocument: Document
    editDocument: Document
    deleteDocument: Document
    addSkillMap: SkillMap
    editSkillMap: SkillMap
    deleteSkillMap: SkillMap
    editDefaultSkillMap: DefaultSkillMap
    tick: Tick
    untick: Tick
}

export type MutationAddDocumentArgs = {
    document: DocumentInput
}

export type MutationEditDocumentArgs = {
    document: DocumentInput
}

export type MutationDeleteDocumentArgs = {
    document: DocumentInput
}

export type MutationAddSkillMapArgs = {
    skillMap: SkillMapInput
}

export type MutationEditSkillMapArgs = {
    skillMap: SkillMapInput
}

export type MutationDeleteSkillMapArgs = {
    skillMap: SkillMapInput
}

export type MutationEditDefaultSkillMapArgs = {
    defaultSkillMap: DefaultSkillMapInput
}

export type MutationTickArgs = {
    tick: TickInput
}

export type MutationUntickArgs = {
    tick: TickInput
}

export enum NodeType {
    MAP = 'map',
    DOCUMENT = 'document',
    NOTE = 'note',
    IMAGE = 'image',
}

export type Query = {
    __typename?: 'Query'
    getTree: Array<Document>
    getTicks: Array<Tick>
    getSkillMaps: Array<SkillMap>
    getSkillMapWithID: SkillMap
    getDefaultSkillMap: Array<DefaultSkillMap>
}

export type QueryGetTicksArgs = {
    userId: Scalars['ID']
}

export type QueryGetSkillMapWithIdArgs = {
    mapId: Scalars['ID']
}

export enum SkillLevel {
    JUNIOR = 'junior',
    ESTABLISHED = 'established',
    SENIOR = 'senior',
}

export type SkillMap = {
    __typename?: 'SkillMap'
    id: Scalars['ID']
    title: Scalars['String']
    description: Scalars['String']
    backgroundColor: Scalars['String']
    skillNodes: Array<SkillNode>
}

export type SkillMapInput = {
    id: Scalars['ID']
    title: Scalars['String']
    description: Scalars['String']
    backgroundColor: Scalars['String']
    skillNodes: Array<SkillNodeInput>
}

export type SkillNode = {
    __typename?: 'SkillNode'
    id: Scalars['ID']
    nodeType: NodeType
    content: Scalars['String']
    x: Scalars['Int']
    y: Scalars['Int']
    connections: Array<Scalars['String']>
    options: Scalars['String']
}

export type SkillNodeInput = {
    id: Scalars['ID']
    nodeType: NodeType
    content: Scalars['String']
    x: Scalars['Int']
    y: Scalars['Int']
    connections: Array<Scalars['String']>
    options: Scalars['String']
}

export type Tick = {
    __typename?: 'Tick'
    id: Scalars['ID']
    itemId: Scalars['ID']
    userId: Scalars['ID']
}

export type TickInput = {
    __typename?: 'TickInput'
    id: Scalars['ID']
    itemId: Scalars['ID']
    userId: Scalars['ID']
}
