import { NodeType } from 'graphql/types'
import { cloneDeep, debounce } from 'lodash'
import { MapPageApolloReturn } from 'pages/MapPage/useMapPageApollo'
import { MapPageNavigationReturn } from 'pages/MapPage/useMapPageNavigation'
import { useCallback, useEffect, useState } from 'react'
import { cleanSkillMap } from 'utils/types'
import { v4 } from 'uuid'

export interface DrawerNoteNodeStateReturn {
    open: boolean
    setOpen: (open: boolean) => void

    note: string
    setNote: (note: string) => void

    editId: string | undefined
    setEditId: (editId: string | undefined) => void

    addNote: (note: string) => void
    editNote: (note: string) => void
}

export const useDrawerNoteNode = (
    apollo: MapPageApolloReturn,
    navigation: MapPageNavigationReturn,
): DrawerNoteNodeStateReturn => {
    const { editSkillMap } = apollo
    const { skillMap, setCenter } = navigation

    const [open, setOpen] = useState<boolean>(false)
    const [note, setNote] = useState<string>('')
    const [editId, setEditId] = useState<string | undefined>(undefined)

    const addNote = (note: string) => {
        setCenter(0, 0)
        editSkillMap({
            skillMap: cleanSkillMap({
                ...skillMap,
                skillNodes: [
                    ...skillMap.skillNodes,
                    {
                        id: v4(),
                        connections: [],
                        content: note,
                        nodeType: NodeType.NOTE,
                        x: 0,
                        y: 0,
                        options: '',
                    },
                ],
            }),
        })
        setOpen(false)
    }

    const debouncedEditNote = useCallback(
        debounce((note: string) => {
            const copy = cloneDeep(skillMap)
            const index = skillMap.skillNodes.findIndex((node) => node.id === editId)
            if (index !== -1) {
                copy.skillNodes.splice(index, 1, {
                    ...skillMap.skillNodes[index],
                    content: note,
                })
                editSkillMap({ skillMap: cleanSkillMap(copy) })
            }
        }, 500),
        [editSkillMap, editId],
    )

    useEffect(() => {
        if (open === false) {
            setNote('')
            setEditId(undefined)
        }
    }, [open])

    return {
        open,
        setOpen,
        note,
        setNote,
        editId,
        setEditId,
        addNote,
        editNote: debouncedEditNote,
    }
}
