import { ActiveUser, Loadstate } from 'utils/types'

export enum GeneralStateAction {
    DEFAULT = 'DEFAULT', // This is only for testing purposes
    SET_LOADSTATE = 'SET_LOADSTATE',
    SET_ACTIVE_USER = 'SET_ACTIVE_USER',
    SET_VALUE = 'SET_VALUE',
}

type GeneralStateActions =
    | { type: GeneralStateAction.DEFAULT }
    | { type: GeneralStateAction.SET_VALUE; payload: number }
    | { payload: Loadstate; type: GeneralStateAction.SET_LOADSTATE }
    | { payload: ActiveUser; type: GeneralStateAction.SET_ACTIVE_USER }

export default GeneralStateActions
