import gql from 'graphql-tag'

export default gql`
    mutation DeleteDocument($document: DocumentInput!) {
        deleteDocument(document: $document) {
            id
            parentId
            order
        }
    }
`
