import gql from 'graphql-tag'

export default gql`
    query getTree {
        getTree {
            id
            order
            parentId
            title
            description
            links {
                id
                url
                label
                comment
            }
            items {
                id
                title
                requirements
                createdAt
                lastEdit
            }
            ready
        }
    }
`
