import { Document as FlatDocument } from 'graphql/types'

export type Document = FlatDocument & { documents: Array<Document> }

export const treeFromFlatTree = (input: FlatDocument[], parentId = ''): Document[] => {
    const tree: Document[] = input
        .filter((value) => value.parentId === parentId)
        .map((value) => ({ ...value, documents: [] }))
    tree.forEach((document) => (document.documents = treeFromFlatTree(input, document.id)))
    return tree
}

export const treeFromFlatTreeWithCondition = (
    input: FlatDocument[],
    condition: (document: FlatDocument) => boolean,
    parentId = '',
): Document[] => {
    const tree: Document[] = input
        .filter((value) => value.parentId === parentId && condition(value))
        .map((value) => ({ ...value, documents: [] }))
    tree.forEach((document) => (document.documents = treeFromFlatTreeWithCondition(input, condition, document.id)))
    return tree
}

export const flatTreeFromTree = (input: Document[]): FlatDocument[] => {
    const tree: FlatDocument[] = input
    input.forEach((document) => tree.push(...flatTreeFromTree(document.documents)))

    return tree
}

export const treeFindById = (documents: Document[], id: string): Document | undefined => {
    for (let i = 0; i < documents.length; i++) {
        const document = documents.find((document) => document.id === id)
        if (document) return document

        const document2 = treeFindById(documents[i].documents, id)
        if (document2) return document2
    }

    return undefined
}

export const calculateDepth = (document: Document, tree: Document[]): number => {
    let parentId = document.parentId
    let depth = 0
    while (parentId !== '') {
        const doc = tree.find((doc: any) => doc.id === parentId)
        if (doc !== undefined) {
            depth++
            parentId = doc.parentId
        } else break
    }
    return depth
}

export const treeFindByAttribute = (
    documents: Document[],
    found: (document: Document) => boolean,
): Document | undefined => {
    for (let i = 0; i < documents.length; i++) {
        const document = documents.find(found)
        if (document) return document

        const document2 = treeFindByAttribute(documents[i].documents, found)
        if (document2) return document2
    }

    return undefined
}

export const treeSort = (documents: Document[], sort: (a: Document, b: Document) => number): Document[] => {
    documents.sort(sort)
    documents.forEach((document) => treeSort(document.documents, sort))
    return documents
}
