import { Add, Close } from '@mui/icons-material'
import { Box, Button, Drawer, IconButton, TextField, Typography } from '@mui/material'
import { drawerWidth } from 'pages/MapPage/useMapPageUtil'
import colors from 'utils/colors'
import { DrawerDocumentNodeStateReturn } from './useDrawerDocumentNode'

export const DrawerDocumentNode = (props: DrawerDocumentNodeStateReturn): JSX.Element => {
    const { open, setOpen, addDocumentNode, search, searchResults, setSearch } = props

    return (
        <Drawer anchor="right" open={open} variant={'temporary'} ModalProps={{ onClose: () => setOpen(false) }}>
            <Box display={'flex'} flexDirection={'column'} width={drawerWidth} flex={1}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={2}>
                    <Typography marginRight={1} variant={'h4'}>
                        Add Document
                    </Typography>
                    <IconButton onClick={() => setOpen(false)} style={{ marginLeft: 'auto' }}>
                        <Close />
                    </IconButton>
                </Box>
                <Box display={'flex'} flexDirection={'column'} p={2}>
                    <TextField
                        label={'Search'}
                        variant={'outlined'}
                        style={{ marginBottom: 8 }}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                    {searchResults.map((document) => (
                        <Button
                            key={document.id}
                            onClick={() => addDocumentNode(document)}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: 8,
                                marginTop: 2,
                                marginBottom: 2,
                            }}
                        >
                            <Typography marginRight={1} style={{ color: colors.black, textAlign: 'start' }}>
                                {document.title}
                            </Typography>

                            <Add style={{ marginLeft: 'auto' }} />
                        </Button>
                    ))}
                </Box>
            </Box>
        </Drawer>
    )
}
