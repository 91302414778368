import { useTranslation } from 'react-i18next'
type useI18nResponse = {
    t: (key: string) => string
}
const useI18n = (): useI18nResponse => {
    const { t } = useTranslation()
    const newT = (key: string): string => (t(key) ? t(key) : key)
    return { t: newT }
}
export default useI18n
