import { Error } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

export enum StubStatus {
    loading,
    error,
    noData,
}

export interface StubProps {
    status: StubStatus
}

const Stub = (props: StubProps): JSX.Element => {
    const { status } = props
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={'100vh'}>
            {status === StubStatus.loading && (
                <>
                    <CircularProgress size={120} style={{ marginBottom: 16 }} disableShrink />
                    <Typography variant={'h4'}>Loading, please wait...</Typography>
                </>
            )}
            {status === StubStatus.noData && (
                <>
                    <Error color={'error'} style={{ width: '120px', height: '120px' }} />
                    <Typography variant={'h4'}>No data found</Typography>
                    <Typography variant={'h5'} style={{ marginTop: '16px' }}>
                        Please contact your administrator
                    </Typography>
                </>
            )}
            {status === StubStatus.error && (
                <>
                    <Error color={'error'} style={{ width: '120px', height: '120px' }} />
                    <Typography variant={'h4'}>An error occured</Typography>
                    <Typography variant={'h5'} style={{ marginTop: '16px' }}>
                        Please contact your administrator
                    </Typography>
                </>
            )}
        </Box>
    )
}

export default Stub
