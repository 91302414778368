import gql from 'graphql-tag'

export default gql`
    mutation EditDocument($document: DocumentInput!) {
        editDocument(document: $document) {
            id
            parentId
            order
            title
            description
            links {
                id
                url
                label
                comment
            }
            items {
                id
                title
                requirements
                createdAt
                lastEdit
            }
            ready
        }
    }
`
