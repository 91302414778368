import { DrawerDocumentStateReturn } from 'components/DrawerDocument/useDrawerDocument'
import { DrawerDocumentNodeStateReturn } from 'components/DrawerDocumentNode/useDrawerDocumentNode'
import { DrawerImageNodeStateReturn } from 'components/DrawerImageNode/useDrawerImageNode'
import { DrawerMapNodeStateReturn } from 'components/DrawerMapNode/useDrawerMapNode'
import { DrawerNoteNodeStateReturn } from 'components/DrawerNoteNode/useDrawerNoteNode'
import { useEffect } from 'react'
import { MapPageNavigationReturn } from './useMapPageNavigation'

export const drawerWidth = 480
export const drawerWidthDocument = 800

export interface MapPageUtilStateReturn {
    anyDrawerOpen: boolean
    closeAllDrawers: () => void
    onClickEditCurrentMap: () => void
    openDrawer: (drawer: 'document' | 'documentNode' | 'imageNode' | 'mapNode' | 'noteNode') => void
}

export const useMapPageUtil = (
    navigation: MapPageNavigationReturn,
    drawerDocument: DrawerDocumentStateReturn,
    drawerDocumentNode: DrawerDocumentNodeStateReturn,
    drawerImageNode: DrawerImageNodeStateReturn,
    drawerMapNode: DrawerMapNodeStateReturn,
    drawerNoteNode: DrawerNoteNodeStateReturn,
): MapPageUtilStateReturn => {
    const closeAllDrawers = () => {
        drawerDocument.setOpen(false)
        drawerDocumentNode.setOpen(false)
        drawerImageNode.setOpen(false)
        drawerMapNode.setOpen(false)
        drawerNoteNode.setOpen(false)
    }

    const onClickEditCurrentMap = () => {
        closeAllDrawers()
        drawerMapNode.setSkillMap(navigation.skillMap)
        drawerMapNode.setEditing(true)
        drawerMapNode.setOpen(true)
    }

    const anyDrawerOpen =
        drawerDocument.open ||
        drawerDocumentNode.open ||
        drawerImageNode.open ||
        drawerMapNode.open ||
        drawerNoteNode.open

    const openDrawer = (drawer: 'document' | 'documentNode' | 'imageNode' | 'mapNode' | 'noteNode') => {
        closeAllDrawers()

        if (drawer === 'document') drawerDocument.setOpen(true)
        if (drawer === 'documentNode') drawerDocumentNode.setOpen(true)
        if (drawer === 'imageNode') drawerImageNode.setOpen(true)
        if (drawer === 'mapNode') drawerMapNode.setOpen(true)
        if (drawer === 'noteNode') drawerNoteNode.setOpen(true)
    }

    useEffect(closeAllDrawers, [navigation.navigation])

    return {
        anyDrawerOpen,
        closeAllDrawers,
        onClickEditCurrentMap,
        openDrawer,
    }
}
