import { useKeycloak } from '@react-keycloak/web'
import { Breadcrumb } from 'components/TitleBar/TitleBar'
import { SkillLevel, SkillMap } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useZoomPanHelper } from 'react-flow-renderer'
import { useLocation } from 'react-router-dom'
import { createEmptySkillMap, SuperKeycloakInstance } from 'utils/types'
import { MapPageApolloReturn } from './useMapPageApollo'

export interface MapPageNavigationReturn {
    title: string
    userName: string
    userId: string
    skillLevel: SkillLevel
    setSkillLevel: (skillLevel: SkillLevel) => void
    navigation: string[]
    setNavigation: (navigation: string[]) => void
    skillMap: SkillMap
    back: (() => void) | undefined
    breadcrumbs: Breadcrumb[]

    // This technically belongs into flow hook, but having it in navigation is vastly more convenient, due to otherwise having circle dependencies
    setCenter: (x: number, y: number, zoom?: number | undefined, duration?: number | undefined) => void
}

export const useMapPageNavigation = (props: MapPageApolloReturn): MapPageNavigationReturn => {
    const { skillMaps, defaultSkillMap } = props

    const { keycloak } = useKeycloak()
    const userName = (keycloak.tokenParsed as SuperKeycloakInstance)?.name ?? ''
    const userId = keycloak.tokenParsed?.sub ?? ''

    const { pathname } = useLocation()

    const { setCenter } = useZoomPanHelper()

    const [skillLevel, setSkillLevel] = useState<SkillLevel>(SkillLevel.JUNIOR)
    const [navigation, setNavigation] = useState<string[]>(pathname.split('/').slice(2)) // default skill map is inivisible in navigation but is appended before in every usage

    useEffect(() => {
        // Update url
        history.replaceState(null, '', '/#/Map' + navigation.reduce((prev, curr) => `${prev}/${curr}`, ''))
    }, [navigation])

    const skillMap: SkillMap =
        skillMaps.find(
            (skillMap) => skillMap.id === [defaultSkillMap.defaultSkillMap, ...navigation][navigation.length],
        ) ?? createEmptySkillMap()

    const breadcrumbs: Breadcrumb[] = [defaultSkillMap.defaultSkillMap, ...navigation].map((nav, index) => {
        const skillMap = skillMaps.find((skillMap) => skillMap.id === nav)
        return {
            title: skillMap ? skillMap.title : '...',
            onClick: () => setNavigation(navigation.slice(0, index)),
        }
    })

    const back = navigation.length > 0 ? () => setNavigation(navigation.slice(0, -1)) : undefined

    return {
        navigation,
        setNavigation,
        setSkillLevel,
        skillLevel,
        userId,
        userName,
        skillMap,
        title: 'Skillmap',
        breadcrumbs,
        back,
        setCenter,
    }
}
