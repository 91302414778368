import { Add, Close } from '@mui/icons-material'
import { Box, Button, Divider, Drawer, IconButton, TextField, Typography } from '@mui/material'
import { DefaultSkillMap } from 'graphql/types'
import { drawerWidth } from 'pages/MapPage/useMapPageUtil'
import { HexColorPicker } from 'react-colorful'
import colors from 'utils/colors'
import { DrawerMapNodeStateReturn } from './useDrawerMapNode'

export interface DrawerMapNodeProps extends DrawerMapNodeStateReturn {
    defaultSkillMap: DefaultSkillMap
}

export const DrawerMapNode = (props: DrawerMapNodeProps): JSX.Element => {
    const {
        addExistingSkillMap,
        addNewSkillMap,
        defaultSkillMap,
        deleteSkillMap,
        editDefaultSkillMap,
        editSkillMap,
        editing,
        setOpen,
        open,
        search,
        searchResults,
        setSearch,
        setSkillMap,
        skillMap,
    } = props

    return (
        <Drawer anchor="right" open={open} variant={'temporary'} ModalProps={{ onClose: () => setOpen(false) }}>
            <Box display={'flex'} flexDirection={'column'} width={drawerWidth} flex={1}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={2}>
                    <Typography marginRight={1} variant={'h4'}>
                        {editing ? 'Edit Map' : 'Add Map'}
                    </Typography>
                    <IconButton onClick={() => setOpen(false)} style={{ marginLeft: 'auto' }}>
                        <Close />
                    </IconButton>
                </Box>
                <Box display={'flex'} flexDirection={'column'} padding={2}>
                    <TextField
                        label={'Title'}
                        variant={'outlined'}
                        style={{ marginBottom: 8 }}
                        value={skillMap.title}
                        onChange={(event) => {
                            setSkillMap({ ...skillMap, title: event.target.value })
                            if (editing) editSkillMap({ ...skillMap, title: event.target.value })
                        }}
                    />
                    <TextField
                        label={'Description'}
                        variant={'outlined'}
                        style={{ marginBottom: 8 }}
                        value={skillMap.description}
                        onChange={(event) => {
                            setSkillMap({ ...skillMap, description: event.target.value })
                            if (editing) editSkillMap({ ...skillMap, description: event.target.value })
                        }}
                    />
                    <Box display="flex">
                        <HexColorPicker
                            style={{ flex: 3 }}
                            color={skillMap.backgroundColor}
                            onChange={(event) => {
                                setSkillMap({ ...skillMap, backgroundColor: event })
                                if (editing) editSkillMap({ ...skillMap, backgroundColor: event })
                            }}
                        />
                        <TextField
                            label={'Color'}
                            variant={'outlined'}
                            style={{ marginLeft: 8, flex: 1 }}
                            value={skillMap.backgroundColor}
                            onChange={(event) => {
                                setSkillMap({ ...skillMap, backgroundColor: event.target.value })
                                if (editing) editSkillMap({ ...skillMap, backgroundColor: event.target.value })
                            }}
                        />
                    </Box>
                    {!editing && (
                        <Button variant={'contained'} onClick={() => addNewSkillMap(skillMap)} style={{ marginTop: 8 }}>
                            Add new
                        </Button>
                    )}
                </Box>

                {!editing && (
                    <Box display={'flex'} flexDirection={'column'} padding={2} paddingTop={0}>
                        <Divider style={{ marginBottom: 16 }} />
                        <Typography variant={'h4'} style={{ marginBottom: 8 }}>
                            From existing Skill Map
                        </Typography>
                        <TextField
                            label={'Search'}
                            variant={'outlined'}
                            style={{ marginBottom: 8 }}
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                        {searchResults.map((skillMap) => (
                            <Button
                                key={skillMap.id}
                                onClick={() => addExistingSkillMap(skillMap)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: 8,
                                    marginTop: 2,
                                    marginBottom: 2,
                                }}
                            >
                                <Typography marginRight={1} style={{ color: colors.black, textAlign: 'start' }}>
                                    {skillMap.title}
                                </Typography>

                                <Add style={{ marginLeft: 'auto' }} />
                            </Button>
                        ))}
                    </Box>
                )}
                {editing && (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        marginLeft={2}
                        marginRight={2}
                        marginBottom={1}
                        marginTop={'auto'}
                    >
                        {defaultSkillMap.defaultSkillMap !== skillMap.id && (
                            <Button
                                color="error"
                                fullWidth
                                variant={'outlined'}
                                onClick={() => {
                                    editDefaultSkillMap({
                                        ...defaultSkillMap,
                                        defaultSkillMap: skillMap.id,
                                    })
                                }}
                            >
                                Set as default Skill Map
                            </Button>
                        )}

                        <Button
                            variant={'outlined'}
                            onClick={deleteSkillMap}
                            fullWidth
                            disabled={skillMap.id === defaultSkillMap.defaultSkillMap}
                        >
                            {skillMap.id === defaultSkillMap.defaultSkillMap
                                ? 'Default Skill Map cannot be deleted'
                                : 'Delete'}
                        </Button>
                    </Box>
                )}
            </Box>
        </Drawer>
    )
}
