import { ImageContentOptions } from 'components/SkillNodeComponent/SkillNodeComponent'
import { NodeType } from 'graphql/types'
import { cloneDeep, debounce } from 'lodash'
import { MapPageApolloReturn } from 'pages/MapPage/useMapPageApollo'
import { MapPageNavigationReturn } from 'pages/MapPage/useMapPageNavigation'
import { useCallback, useEffect, useState } from 'react'
import { cleanSkillMap } from 'utils/types'
import { v4 } from 'uuid'

export interface DrawerImageNodeStateReturn {
    open: boolean
    setOpen: (open: boolean) => void

    source: string
    setSource: (source: string) => void

    editId: string | undefined
    setEditId: (editId: string | undefined) => void

    options: ImageContentOptions
    setOptions: (options: ImageContentOptions) => void

    addImage: (source: string, options: ImageContentOptions) => void
    editImage: (source: string, options: ImageContentOptions) => void
}

export const useDrawerImageNode = (
    apollo: MapPageApolloReturn,
    navigation: MapPageNavigationReturn,
): DrawerImageNodeStateReturn => {
    const { editSkillMap } = apollo
    const { skillMap, setCenter } = navigation

    const [open, setOpen] = useState<boolean>(false)
    const [source, setSource] = useState<string>('')
    const [editId, setEditId] = useState<string | undefined>(undefined)

    const node = skillMap.skillNodes.find((node) => node.id === editId)
    const [options, setOptions] = useState(
        node && node.options !== '' ? JSON.parse(node.options) : { height: 100, width: 100 },
    )

    const addImage = (source: string, options: ImageContentOptions) => {
        setCenter(0, 0)
        editSkillMap({
            skillMap: cleanSkillMap({
                ...skillMap,
                skillNodes: [
                    ...skillMap.skillNodes,
                    {
                        id: v4(),
                        connections: [],
                        content: source,
                        nodeType: NodeType.IMAGE,
                        x: 0,
                        y: 0,
                        options: JSON.stringify(options),
                    },
                ],
            }),
        })
        setOpen(false)
    }

    const debouncedEditImage = useCallback(
        debounce((source: string, options: ImageContentOptions) => {
            const copy = cloneDeep(skillMap)
            const index = skillMap.skillNodes.findIndex((node) => node.id === editId)
            if (index !== -1) {
                copy.skillNodes.splice(index, 1, {
                    ...skillMap.skillNodes[index],
                    content: source,
                    options: JSON.stringify(options),
                })
                editSkillMap({ skillMap: cleanSkillMap(copy) })
            }
        }, 500),
        [editSkillMap, editId],
    )

    useEffect(() => {
        if (open === false) {
            setSource('')
            setEditId(undefined)
        }
    }, [open])

    return {
        addImage,
        editImage: debouncedEditImage,
        open,
        setOpen,
        editId,
        options,
        setOptions,
        setEditId,
        setSource,
        source,
    }
}
