import { Box } from '@mui/material'
import { DialogDelete } from 'components/DialogDelete/DialogDelete'
import { DrawerDocument } from 'components/DrawerDocument/DrawerDocument'
import { useDrawerDocument } from 'components/DrawerDocument/useDrawerDocument'
import { DrawerDocumentNode } from 'components/DrawerDocumentNode/DrawerDocumentNode'
import { useDrawerDocumentNode } from 'components/DrawerDocumentNode/useDrawerDocumentNode'
import { DrawerImageNode } from 'components/DrawerImageNode/DrawerImageNode'
import { useDrawerImageNode } from 'components/DrawerImageNode/useDrawerImageNode'
import { DrawerMapNode } from 'components/DrawerMapNode/DrawerMapNode'
import { useDrawerMapNode } from 'components/DrawerMapNode/useDrawerMapNode'
import { DrawerNoteNode } from 'components/DrawerNoteNode/DrawerNoteNode'
import { useDrawerNoteNode } from 'components/DrawerNoteNode/useDrawerNoteNode'
import Fabs from 'components/Fabs/Fabs'
import { useFabs } from 'components/Fabs/useFabs'
import {
    DocumentContent,
    ImageContent,
    MapContent,
    NoteContent,
} from 'components/SkillNodeComponent/SkillNodeComponent'
import Stub from 'components/Stub'
import { TitleBar } from 'components/TitleBar/TitleBar'
import ReactFlow, { Controls } from 'react-flow-renderer'
import colors from 'utils/colors'
import { useMapPageApollo } from './useMapPageApollo'
import { useMapPageFlow } from './useMapPageFlow'
import { useMapPageNavigation } from './useMapPageNavigation'
import { useMapPageUtil } from './useMapPageUtil'

const nodeTypes = { map: MapContent, document: DocumentContent, note: NoteContent, image: ImageContent }

export const MapPage = (): JSX.Element => {
    const apollo = useMapPageApollo()
    const navigation = useMapPageNavigation(apollo)
    const drawerDocument = useDrawerDocument()
    const drawerDocumentNode = useDrawerDocumentNode(apollo, navigation)
    const drawerImageNode = useDrawerImageNode(apollo, navigation)
    const drawerMapNode = useDrawerMapNode(apollo, navigation)
    const drawerNoteNode = useDrawerNoteNode(apollo, navigation)
    const util = useMapPageUtil(
        navigation,
        drawerDocument,
        drawerDocumentNode,
        drawerImageNode,
        drawerMapNode,
        drawerNoteNode,
    )
    const fabs = useFabs(util)
    const flow = useMapPageFlow(
        apollo,
        navigation,
        util,
        drawerDocument,
        drawerImageNode,
        drawerMapNode,
        drawerNoteNode,
    )

    if (apollo.state) return <Stub status={apollo.state} />

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            bgcolor={colors.white}
            maxHeight={'100vh'}
            height={'100vh'}
            overflow="auto"
            width={'100vw'}
        >
            <TitleBar
                selectedDocument={undefined}
                {...navigation}
                showEditButton={!drawerMapNode.open}
                onClickEditButton={util.onClickEditCurrentMap}
            />
            <Box display="flex" flex={1} bgcolor={navigation.skillMap.backgroundColor}>
                <ReactFlow {...flow} nodeTypes={nodeTypes} snapGrid={[16, 16]} snapToGrid>
                    <Controls showInteractive />
                </ReactFlow>
            </Box>
            <Fabs {...fabs} />

            <DrawerDocument {...drawerDocument} {...apollo} {...navigation} />

            <DrawerDocumentNode {...drawerDocumentNode} {...apollo} />

            <DrawerImageNode {...drawerImageNode} />

            <DrawerMapNode {...apollo} {...drawerMapNode} />

            <DrawerNoteNode {...drawerNoteNode} />

            <DialogDelete {...drawerMapNode} />
        </Box>
    )
}
