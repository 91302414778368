import { Close } from '@mui/icons-material'
import { Box, Button, Checkbox, Divider, Drawer, IconButton, Typography } from '@mui/material'
import { StyledTooltip } from 'components/StyledTooltip/StyledTooltip'
import { SkillLevel, Tick as TickType } from 'graphql/types'
import { cloneDeep } from 'lodash'
import { drawerWidthDocument } from 'pages/MapPage/useMapPageUtil'
import ReactMarkdown from 'react-markdown'
import { checkboxColor } from 'utils/functions/functions'
import { DrawerDocumentStateReturn } from './useDrawerDocument'

export interface DrawerDocumentProps extends DrawerDocumentStateReturn {
    skillLevel: SkillLevel
    ticks: TickType[]
    tick: (itemId: string) => void
    untick: (itemId: string) => void
}

export const DrawerDocument = (props: DrawerDocumentProps): JSX.Element => {
    const { open, document, skillLevel, ticks, tick, untick, setOpen } = props

    const filteredItems = cloneDeep(document.items)
    filteredItems.sort((a, b) => {
        const colorA = checkboxColor(a.requirements, skillLevel)
        const colorB = checkboxColor(b.requirements, skillLevel)
        if (colorA === colorB) return 0
        else if (colorA === 'red') return -1
        else if (colorB === 'red') return 1
        else if (colorA === 'gold') return -1
        else return 1
    })

    return (
        <Drawer anchor="right" open={open} variant={'temporary'} ModalProps={{ onClose: () => setOpen(false) }}>
            <Box display={'flex'} flexDirection={'column'} width={drawerWidthDocument} height={'100vh'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={2} paddingBottom={0}>
                    <Typography variant={'h1'}>{document.title}</Typography>
                    <IconButton onClick={() => setOpen(false)} style={{ marginLeft: 'auto' }}>
                        <Close />
                    </IconButton>
                </Box>
                <Box
                    display="flex"
                    flexDirection={'column'}
                    padding={2}
                    style={{ overflowY: 'auto', overflowX: 'hidden' }}
                    paddingTop={0}
                >
                    <Box>
                        <ReactMarkdown rawSourcePos>{document.description}</ReactMarkdown>
                    </Box>
                    {document.links.length > 0 && (
                        <>
                            <Divider style={{ marginTop: 0, marginBottom: 16 }} />
                            <Typography variant={'h3'}>Where to learn:</Typography>
                            <Box display={'flex'} flexWrap={'wrap'} marginTop={1} flexDirection={'column'}>
                                {document.links.map((link) => (
                                    <Box key={link.id}>
                                        <StyledTooltip title={link.url}>
                                            <Button
                                                fullWidth
                                                sx={{
                                                    textDecoration: 'underline',
                                                    justifyContent: 'flex-start',
                                                    minWidth: 0,
                                                }}
                                                href={
                                                    link.url.includes('https://') || link.url.includes('http://')
                                                        ? link.url
                                                        : link.url.includes('https://')
                                                        ? `https://` + link.url
                                                        : `http://` + link.url
                                                }
                                                target={'_blank'}
                                            >
                                                {link.label}
                                            </Button>
                                        </StyledTooltip>
                                    </Box>
                                ))}
                            </Box>
                        </>
                    )}
                    {filteredItems.length > 0 && (
                        <>
                            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                            <Typography variant={'h3'}>Skillset checklist:</Typography>
                            {filteredItems.map((item) => (
                                <Box key={item.id} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Checkbox
                                        style={{ color: checkboxColor(item.requirements, skillLevel) }}
                                        checked={ticks.some((tick) => tick.id === item.id)}
                                        onChange={(event) => {
                                            if (event.target.checked) tick(item.id)
                                            else untick(item.id)
                                        }}
                                    />
                                    <Typography>{item.title}</Typography>
                                </Box>
                            ))}
                        </>
                    )}
                </Box>
            </Box>
        </Drawer>
    )
}
