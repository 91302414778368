import { Box, Typography } from '@mui/material'
import { Document, SkillMap, SkillNode } from 'graphql/types'
import colors from 'utils/colors'

const maxWidth = 160
const maxHeight = 4 * maxWidth

export interface SkillNodeProps {
    data: {
        node: SkillNode
        tree: Document[]
        skillMaps: SkillMap[]
    }
}

export const DocumentContent = (props: SkillNodeProps): JSX.Element => {
    const { data } = props
    const { node, tree } = data
    return (
        <Box
            display="flex"
            minHeight={'45px'}
            width={'192px'}
            alignItems="center"
            justifyContent={'center'}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            padding={1}
            border={`1px solid ${colors.lightGray}`}
        >
            {/* <Handle type="target" position={Position.Top} /> */}
            <Typography variant={'h4'} style={{ wordBreak: 'break-word' }}>
                {tree.find((doc) => doc.id === node.content)?.title ?? 'DOCUMENT NOT FOUND'}
            </Typography>
            {/* <Handle type="source" position={Position.Bottom} /> */}
        </Box>
    )
}

export const MapContent = (props: SkillNodeProps): JSX.Element => {
    const { data } = props
    const { node, skillMaps } = data

    const skillMap = skillMaps.find((map) => map.id === node.content) ?? {
        id: '',
        backgroundColor: '',
        title: 'ERROR',
        description: 'NOT FOUND',
        skillNodes: [],
    }
    return (
        <Box
            display="flex"
            flexDirection={'column'}
            minHeight={'45px'}
            width={'192px'}
            alignItems="center"
            justifyContent={'center'}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            padding={1}
            border={`1px solid ${colors.lightGray}`}
        >
            {/* <Handle type="target" position={Position.Top} /> */}
            <Typography variant={'h4'} style={{ wordBreak: 'break-word' }}>
                {skillMap.title}
            </Typography>
            <Typography variant={'body1'} style={{ wordBreak: 'break-word' }}>
                {skillMap.description}
            </Typography>
            {/* <Handle type="source" position={Position.Bottom} /> */}
        </Box>
    )
}

export const NoteContent = (props: SkillNodeProps): JSX.Element => {
    const { data } = props
    const { node } = data

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent={'center'}
            width={'192px'}
            flexDirection="column"
            borderRadius={'1px'}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            padding={1}
            border={`1px solid ${colors.lightGray}`}
        >
            <Typography
                variant={'body1'}
                style={{
                    wordBreak: 'break-word',
                }}
            >
                {node.content}
            </Typography>
        </Box>
    )
}

export interface ImageContentOptions {
    width: number
}
export const ImageContent = (props: SkillNodeProps): JSX.Element => {
    const { data } = props
    const { node } = data
    const options: ImageContentOptions = node.options !== '' ? JSON.parse(node.options) : { width: 64 }

    return (
        <Box display="flex" justifyContent="space-evenly" alignItems={'center'}>
            <img
                style={{
                    width: options.width,
                    height: 'auto',
                    objectFit: 'contain',
                }}
                src={node.content}
                alt={'Invalid or missing URL'}
                draggable={false}
            />
        </Box>
    )
}
