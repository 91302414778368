import { Box, Fab } from '@mui/material'
import { StyledTooltip } from 'components/StyledTooltip/StyledTooltip'
import colors from 'utils/colors'

export interface FabsProps {
    extended: boolean
    onClickExtend: () => void
    extendIcon: JSX.Element

    fabs: FabProps[]
}

export interface FabProps {
    icon: JSX.Element
    tooltip: string
    onClick: () => void
}

const Fabs = (props: FabsProps): JSX.Element => {
    const { extended, extendIcon, fabs, onClickExtend } = props

    const renderFab = (fab: FabProps, index: number) => {
        return (
            <StyledTooltip key={index} title={fab.tooltip} placement="left">
                <span>
                    <Fab
                        onClick={fab.onClick}
                        disabled={!extended}
                        size={'small'}
                        style={{
                            color: colors.white,
                            backgroundColor: colors.main,
                            zIndex: 200,
                            opacity: extended ? 1 : 0,
                            transition: 'opacity 500ms ease-in-out',
                            marginBottom: 4,
                        }}
                    >
                        {fab.icon}
                    </Fab>
                </span>
            </StyledTooltip>
        )
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            position={'absolute'}
            bottom={16}
            right={16}
            alignItems={'center'}
        >
            {/* FIXME: Tooltips keep showing after you click a Fab*/}
            {fabs.map((fab, index) => renderFab(fab, index))}
            <Fab
                onClick={onClickExtend}
                style={{
                    zIndex: 100,
                    color: extended ? colors.darkGray : colors.white,
                    backgroundColor: extended ? colors.midGray : colors.main,
                    transition: 'color 500ms ease-in-out, background-color 500ms ease-in-out',
                }}
            >
                {extendIcon}
            </Fab>
        </Box>
    )
}
export default Fabs
