import { useEffect } from 'react'

export default function useVersionTitle(): void {
    const pkg = require('../../package.json')
    // --- DO NOT EDIT THIS LINE MANUALLY. UPDATE THROUGH 'yarn countCommits'
    const appBuildNumber = 36
    const newTitle = pkg.name + ' - ' + pkg.version + ':' + appBuildNumber
    useEffect(
        () => {
            document.title = newTitle
        },
        // eslint-disable-next-line
        [],
    )
}
