import { AccountTree, Add, AddPhotoAlternate, PostAdd, Summarize } from '@mui/icons-material'
import { MapPageUtilStateReturn } from 'pages/MapPage/useMapPageUtil'
import { useState } from 'react'
import { FabProps } from './Fabs'

export interface FabsStateReturn {
    extended: boolean
    onClickExtend: () => void
    extendIcon: JSX.Element
    fabs: FabProps[]
}

export const useFabs = (util: MapPageUtilStateReturn): FabsStateReturn => {
    const { openDrawer } = util

    const [extended, setExtended] = useState<boolean>(false)

    const onClickExtend = () => setExtended(true)
    const extendIcon = <Add fontSize="large" />

    const createOnClick = (drawer: 'document' | 'documentNode' | 'imageNode' | 'mapNode' | 'noteNode') => {
        return () => {
            setExtended(false)
            openDrawer(drawer)
        }
    }

    const fabs = [
        { icon: <PostAdd fontSize="small" />, onClick: createOnClick('noteNode'), tooltip: 'Add a note' },
        {
            icon: <AddPhotoAlternate fontSize="small" />,
            onClick: createOnClick('imageNode'),
            tooltip: 'Add a picture',
        },
        {
            icon: <AccountTree fontSize="small" />,
            onClick: createOnClick('mapNode'),
            tooltip: 'Add a new map node',
        },
        {
            icon: <Summarize fontSize="small" />,
            onClick: createOnClick('documentNode'),
            tooltip: 'Add a new document node',
        },
    ]

    return {
        extendIcon,
        extended,
        onClickExtend,
        fabs,
    }
}
