import { TreeItem } from '@mui/lab'
import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import colors from 'utils/colors'

const BaseSkillTreeItem = styled(TreeItem)(() => ({
    '& .Mui-focused': {
        backgroundColor: 'transparent',
    },
    '& .MuiTreeItem-content': {
        '&.Mui-focused': {
            backgroundColor: 'transparent',
        },
        '&:hover': {
            backgroundColor: colors.mainFocused,
        },
        '&.Mui-selected': {
            backgroundColor: colors.main,
            color: colors.white,
            '&.Mui-focused': {
                backgroundColor: colors.main,
                color: colors.white,
            },
        },
    },
    '& .Mui-selected:hover': {
        color: colors.black,
        backgroundColor: colors.mainFocused,
        '&:hover': {
            backgroundColor: colors.mainTooltip,
            color: colors.black,
        },
    },
}))

export interface SkillTreeItemProps {
    id: string
    title: string
    required: number
    recommended: number
    depth: number
}

export const SkillTreeItem = (props: React.PropsWithChildren<SkillTreeItemProps>): JSX.Element => {
    const { id, title, required, recommended, depth, children } = props
    return (
        <BaseSkillTreeItem
            nodeId={id}
            label={
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Typography
                        style={{
                            paddingRight: 8,
                            wordBreak: 'break-word',
                            lineHeight: 2.5,
                            fontWeight: Math.max(700 - depth * 300, 100),
                        }}
                    >
                        {title}
                    </Typography>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        marginLeft={'auto'}
                        flexDirection={'row'}
                    >
                        <Typography
                            style={{
                                minWidth: 32,
                                width: 32,
                            }}
                            variant={'caption'}
                        >
                            {required}
                        </Typography>
                        <Typography variant={'caption'} style={{ minWidth: 32, width: 32 }}>
                            {recommended}
                        </Typography>
                    </Box>
                </Box>
            }
        >
            {children}
        </BaseSkillTreeItem>
    )
}
