import { Close } from '@mui/icons-material'
import { Box, Button, Drawer, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { drawerWidth } from 'pages/MapPage/useMapPageUtil'
import { DrawerImageNodeStateReturn } from './useDrawerImageNode'

const widths = [100, 200, 300, 400, 500, 600, 700, 800]

export const DrawerImageNode = (props: DrawerImageNodeStateReturn): JSX.Element => {
    const { addImage, editId, editImage, open, options, setOptions, setOpen, setSource, source, setEditId } = props

    return (
        <Drawer anchor="right" open={open} variant={'temporary'} ModalProps={{ onClose: () => setOpen(false) }}>
            <Box display={'flex'} flexDirection={'column'} width={drawerWidth} flex={1} padding={2}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Typography marginRight={1} variant={'h4'}>
                        {editId === undefined ? 'Add Image' : 'Edit Image'}
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setOpen(false)
                            setEditId(undefined)
                            setSource('')
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        <Close />
                    </IconButton>
                </Box>

                <Box display={'flex'} flexDirection={'column'} marginTop={4}>
                    <img
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: `${drawerWidth - 32 - 128}px`,
                            height: 'auto',
                            marginBottom: '8px',
                            objectFit: 'contain',
                        }}
                        src={source}
                        alt={source === '' ? 'Please paste an image URL' : 'Invalid URL'}
                    />

                    <TextField
                        value={options.width}
                        onChange={(event) => {
                            setOptions({ width: Number(event.target.value) })
                            if (editId !== undefined) editImage(source, { width: Number(event.target.value) })
                        }}
                        select // This is a select, you just dont know it
                        label={'Image width'}
                    >
                        {widths.map((w) => (
                            <MenuItem key={w} value={w}>
                                {w}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label={'Source'}
                        variant={'outlined'}
                        style={{ marginBottom: 8, marginTop: 16 }}
                        value={source}
                        onChange={(event) => {
                            setSource(event.target.value)
                            if (editId !== undefined) editImage(event.target.value, options)
                        }}
                    />
                    {editId === undefined && (
                        <Button
                            variant={'contained'}
                            onClick={() => addImage(source, options)}
                            style={{ marginTop: 8 }}
                        >
                            Add
                        </Button>
                    )}
                </Box>
            </Box>
        </Drawer>
    )
}
