import gql from 'graphql-tag'

export default gql`
    mutation Tick($tick: TickInput!) {
        tick(tick: $tick) {
            id
            itemId
            userId
        }
    }
`
