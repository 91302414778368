import { FlowElement } from 'react-flow-renderer'
import { Document, NodeType, SkillMap, SkillNode } from '../../graphql/types'

const colorMap = {
    [NodeType.MAP]: '#15f5f5',
    [NodeType.DOCUMENT]: '#dccc33',
    [NodeType.IMAGE]: '#f5f5f500',
    [NodeType.NOTE]: '#f5f5a5',
}

export const createNode = (node: SkillNode, tree: Document[], skillMaps: SkillMap[]): FlowElement => {
    return {
        id: node.id,
        data: {
            node,
            tree,
            skillMaps,
        },
        type: node.nodeType.toLowerCase(),
        position: { x: node.x, y: node.y },
        style: {
            backgroundColor: colorMap[node.nodeType],
            cursor: node.nodeType === NodeType.IMAGE ? 'default' : 'pointer',
        },
    }
}

export const createEdges = (node: SkillNode): FlowElement[] => {
    return node.connections.map((connection) => {
        return {
            id: `edgeFrom_${node.id}_To_${connection}`,
            type: 'straight',
            source: node.id,
            target: connection,
            animated: true,
        }
    })
}
