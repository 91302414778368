import { Document } from 'graphql/types'
import { useState } from 'react'
import { createEmptyDocument } from 'utils/types'

export interface DrawerDocumentStateReturn {
    open: boolean
    setOpen: (open: boolean) => void
    document: Document
    setDocument: (document: Document) => void
}

export const useDrawerDocument = (): DrawerDocumentStateReturn => {
    const [open, setOpen] = useState<boolean>(false)
    const [document, setDocument] = useState<Document>(createEmptyDocument())

    return {
        open,
        setOpen,
        document,
        setDocument,
    }
}
