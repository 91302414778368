import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DrawerMapNodeStateReturn } from 'components/DrawerMapNode/useDrawerMapNode'
import React from 'react'
import colors from 'utils/colors'

export const DialogDelete = (props: DrawerMapNodeStateReturn): JSX.Element => {
    const { openDialog, onCancel, onConfirm } = props
    return (
        <Dialog open={openDialog} onClose={onCancel}>
            <DialogTitle style={{ fontSize: 24, color: colors.main }}>Delete Map?</DialogTitle>
            <DialogContent>
                <DialogContentText color={colors.black}>
                    This action will permanently delete this map and all corresponding nodes on all maps. This action is
                    not reversible.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
