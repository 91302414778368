import { DefaultSkillMap, NodeType, SkillMap } from 'graphql/types'
import { debounce } from 'lodash'
import { MapPageApolloReturn } from 'pages/MapPage/useMapPageApollo'
import { MapPageNavigationReturn } from 'pages/MapPage/useMapPageNavigation'
import { useCallback, useEffect, useState } from 'react'
import { cleanDefaultSkillMap, cleanSkillMap, createEmptySkillMap } from 'utils/types'
import { v4 } from 'uuid'

export interface DrawerMapNodeStateReturn {
    open: boolean
    setOpen: (open: boolean) => void
    editing: boolean
    setEditing: (editing: boolean) => void
    search: string
    setSearch: (search: string) => void
    searchResults: SkillMap[]
    skillMap: SkillMap
    setSkillMap: (skillMap: SkillMap) => void

    addNewSkillMap: (skillMap: SkillMap) => void
    addExistingSkillMap: (skillMap: SkillMap) => void
    editSkillMap: (skillMap: SkillMap) => void
    deleteSkillMap: () => void
    editDefaultSkillMap: (defaultSkillMap: DefaultSkillMap) => void

    openDialog: boolean
    onConfirm: () => void
    onCancel: () => void
}

export const useDrawerMapNode = (
    apollo: MapPageApolloReturn,
    navigation: MapPageNavigationReturn,
): DrawerMapNodeStateReturn => {
    const {
        skillMaps,
        addSkillMap,
        editSkillMap,
        editDefaultSkillMap: editDefaultSkillMapApollo,
        deleteSkillMap: deleteSkillMapApollo,
    } = apollo
    const { skillMap: currentSkillMap, setCenter, setNavigation, back } = navigation

    const [open, setOpen] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const [editing, setEditing] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [skillMap, setSkillMap] = useState<SkillMap>(createEmptySkillMap())

    useEffect(() => {
        if (!open) {
            setSkillMap(createEmptySkillMap())
            setEditing(false)
            setSearch('')
            setOpenDialog(false)
        }
    }, [open])

    const addNewSkillMap = (newMap: SkillMap) => {
        addSkillMap({
            skillMap: cleanSkillMap(newMap),
        })

        setCenter(0, 0)

        editSkillMap({
            skillMap: cleanSkillMap({
                ...currentSkillMap,
                skillNodes: [
                    ...currentSkillMap.skillNodes,
                    { id: v4(), connections: [], content: newMap.id, nodeType: NodeType.MAP, x: 0, y: 0, options: '' },
                ],
            }),
        })

        setOpen(false)
    }

    const addExistingSkillMap = (existingMap: SkillMap) => {
        setCenter(0, 0)

        editSkillMap({
            skillMap: cleanSkillMap({
                ...currentSkillMap,
                skillNodes: [
                    ...currentSkillMap.skillNodes,
                    {
                        id: v4(),
                        connections: [],
                        content: existingMap.id,
                        nodeType: NodeType.MAP,
                        x: 0,
                        y: 0,
                        options: '',
                    },
                ],
            }),
        })
    }

    const debouncedEditSkillMap = useCallback(
        debounce((skillMap: SkillMap) => {
            editSkillMap({ skillMap: cleanSkillMap(skillMap) })
        }, 500),
        [editSkillMap],
    )

    const deleteSkillMap = () => setOpenDialog(true)

    const editDefaultSkillMap = (defaultSkillMap: DefaultSkillMap) => {
        editDefaultSkillMapApollo({ defaultSkillMap: cleanDefaultSkillMap(defaultSkillMap) })
        setNavigation([])
        setOpen(false)
    }

    const searchResults = skillMaps.filter((skillMap) => skillMap.title.toLowerCase().includes(search.toLowerCase()))

    // Dialog stuff
    const onConfirm = () => {
        // Go back if we delete ourself
        if (skillMap.id === currentSkillMap.id && back) back()
        setOpen(false)

        deleteSkillMapApollo({ skillMap: cleanSkillMap(skillMap) })

        skillMaps.forEach((map) => {
            const filteredNodes = map.skillNodes.filter(
                (skillNode) => skillNode.nodeType !== NodeType.MAP || skillNode.content !== skillMap.id,
            )

            if (filteredNodes.length < map.skillNodes.length)
                editSkillMap({
                    skillMap: cleanSkillMap({
                        ...map,
                        skillNodes: filteredNodes,
                    }),
                })
        })
        setSkillMap(createEmptySkillMap())
    }

    const onCancel = () => setOpen(false)

    return {
        open,
        addExistingSkillMap,
        addNewSkillMap,
        deleteSkillMap,
        editDefaultSkillMap,
        editSkillMap: debouncedEditSkillMap,
        editing,
        setEditing,
        setOpen,
        search,
        searchResults,
        setSearch,
        setSkillMap,
        skillMap,
        openDialog,
        onCancel,
        onConfirm,
    }
}
