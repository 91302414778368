const colors = {
    black: '#000000',
    darkGray: '#929292',
    lightGray: '#F3F0F0',
    midGray: '#C4C4C4',
    white: '#FFFFFF',
    main: '#963683',
    mainFocused: '#ead7e6',
    mainTooltip: '#bc81b1',
    warning: '#EB5757',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    mapNode: '#ececec',
}

export default colors
