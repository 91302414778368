import gql from 'graphql-tag'

export default gql`
    query getSkillMaps {
        getSkillMaps {
            id
            title
            description
            backgroundColor
            skillNodes {
                id
                nodeType
                content
                x
                y
                connections
                options
            }
        }
    }
`
