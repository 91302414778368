import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ActiveUser, Loadstate } from 'utils/types'

export interface GeneralState {
    loadstate: Loadstate
    activeUser: ActiveUser
    value: number
}

export const initialActiveUser: ActiveUser = {
    id: '',
    email: '',
    roles: [],
    ticks: [],
}

export const initialLoadstate: Loadstate = {
    loading: false,
    errors: [],
}

export const initialGeneralState: GeneralState = {
    loadstate: initialLoadstate,
    activeUser: initialActiveUser,
    value: 0,
}

function GeneralStateReducer(state: GeneralState = initialGeneralState, action: GeneralStateActions): GeneralState {
    switch (action.type) {
        case GeneralStateAction.SET_LOADSTATE:
            return {
                ...state,
                loadstate: action.payload,
            }
        case GeneralStateAction.SET_ACTIVE_USER:
            return {
                ...state,
                activeUser: action.payload,
            }
        case GeneralStateAction.SET_VALUE:
            return {
                ...state,
                value: action.payload,
            }
        default:
            return state
    }
}

export default GeneralStateReducer
