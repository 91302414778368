import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import ErrorDialog from 'components/ErrorDialog'
import LoadingSpinner from 'components/LoadingSpinner'
import { MapPage } from 'pages/MapPage/MapPage'
import { OverviewPage } from 'pages/OverviewPage/OverviewPage'
import React from 'react'
import { ReactFlowProvider } from 'react-flow-renderer'
import { Provider } from 'react-redux'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import store from 'store/store'
import 'utils/ i18n'
import getEnvConfig from 'utils/getEnvConfig'
import PrivateRoute from 'utils/PrivateRoute'
import { ModifiedTheme } from 'utils/theme'
import { ErrorConfig } from 'utils/types'
import useVersionTitle from 'utils/useVersionTitle'
import { useApolloClient } from './utils/useApolloClient'

function App(): JSX.Element {
    const envConfig = getEnvConfig()
    useVersionTitle()
    const graphQLServerURI =
        process.env.NODE_ENV !== 'production'
            ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/graphql`
            : '/graphql'
    const [error, setError] = React.useState<ErrorConfig>()
    const { keycloak, initialized } = useKeycloak()
    const apolloClient = useApolloClient(graphQLServerURI, setError, keycloak)

    if (!initialized) {
        return <LoadingSpinner loading={true} />
    }
    return (
        <>
            {apolloClient && (
                <ApolloProvider client={apolloClient}>
                    <HashRouter>
                        <Provider store={store}>
                            <ReactFlowProvider>
                                <ThemeProvider theme={ModifiedTheme}>
                                    {error && <ErrorDialog errorConfig={error} />}
                                    {!error && (
                                        <Switch>
                                            <PrivateRoute exact={true} path={'/Tree'} component={OverviewPage} />
                                            <PrivateRoute exact={true} path={'/Tree/:id'} component={OverviewPage} />

                                            <PrivateRoute exact={true} path={'/Map'} component={MapPage} />
                                            <PrivateRoute exact={true} path={'/Map/*'} component={MapPage} />

                                            <Route>
                                                <Redirect from={'/'} to={'/Tree'} />
                                            </Route>
                                        </Switch>
                                    )}
                                </ThemeProvider>
                            </ReactFlowProvider>
                        </Provider>
                    </HashRouter>
                </ApolloProvider>
            )}
        </>
    )
}

export default App
