import gql from 'graphql-tag'

export default gql`
    mutation EditSkillMap($skillMap: SkillMapInput!) {
        editSkillMap(skillMap: $skillMap) {
            id
            title
            description
            backgroundColor
            skillNodes {
                id
                nodeType
                content
                x
                y
                connections
                options
            }
        }
    }
`
